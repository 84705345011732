import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AddNewTaskDialogModule } from './task-management/task-management-dialog.module';
import { AddNewResourceDialogModule } from './resource-management/resource-management-dialog.module';
import { SwitchUserDialogModule } from './switch-user-management/switch-user-management-dialog.module';
import { ManpowerDialogComponent } from './manpower-dialog.component';
import { AddNewTaskDialogComponent } from './task-management/task-management-dialog.component';
import { AddNewResourceDialogComponent } from './resource-management/resource-management-dialog.component';
import { SwitchUserDialogComponent } from './switch-user-management/switch-user-management-dialog.component';
import { CustomBlockComponent } from './custom-block';
import { CustomListComponent } from './custom-list';
import { CustomSwitchBlockComponent } from './custom-switch-block';

@NgModule({
  exports: [
    MatDialogModule,
  ]
})
export class DemoMaterialModule {}

@NgModule({
  imports: [
    FormsModule,
    HttpClientModule,
    AddNewTaskDialogModule,
    AddNewResourceDialogModule,
    SwitchUserDialogModule,
    CommonModule
  ],
  declarations: [
    AddNewTaskDialogComponent,
    AddNewResourceDialogComponent,
    SwitchUserDialogComponent,
    CustomBlockComponent,
    CustomListComponent,
    CustomSwitchBlockComponent,
    ManpowerDialogComponent
  ],
  entryComponents: [ AddNewTaskDialogComponent, AddNewResourceDialogComponent, SwitchUserDialogComponent ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [ManpowerDialogComponent]
})
export class ManpowerDialogModule {}
