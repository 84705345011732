import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'angular-calendar';
import { WeekCalendarUtilsModule } from './week-calendar-utils/week-calendar.module';
import { WeekCalendarComponent } from './week-calendar.component';

@NgModule({
  imports: [
    CommonModule,
    CalendarModule.forRoot(),
    WeekCalendarUtilsModule
  ],
  declarations: [WeekCalendarComponent],
  exports: [WeekCalendarComponent]
})
export class WeekCalendarModule {}
