import {
  Component,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { SpinnerVisibilityService } from 'ng-http-loader';

import { AppService } from '../../app.service';
import * as projectInfo from '../../globals';
import { AddNewTaskDialogComponent } from './task-management/task-management-dialog.component';
import { AddNewResourceDialogComponent } from './resource-management/resource-management-dialog.component';
import { SwitchUserDialogComponent } from './switch-user-management/switch-user-management-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manpower-dialog',
  templateUrl: './manpower-dialog.component.html',
  styleUrls: ['./manpower-dialog.component.scss']
})
export class ManpowerDialogComponent implements OnInit {

  employeeList: Array<any> = [];
  stopProcess: boolean;
  @Output() refreshManpower: EventEmitter<null> = new EventEmitter();

  constructor(
    private spinner: SpinnerVisibilityService,
    public dialog: MatDialog,
    private appService: AppService
  ) {
  }

  ngOnInit() {
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }

  openEditTask(){
    if(projectInfo.status == true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    if(projectInfo.can_update_task == false){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. You do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    const dialogRef = this.dialog.open(AddNewTaskDialogComponent, {
      width: '75%',
      data: { mode: 1 }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.refreshManpower.emit();
      }
    });
  }

  openAddNewTask() {
    if(projectInfo.status == true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    //check if user has right to create task. if not then show alert and return user from here.
    if(projectInfo.can_create_task == false){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. You do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    console.log('employee list while open dialog box -------------- ', this.employeeList);
    const dialogRef = this.dialog.open(AddNewTaskDialogComponent, {
      width: '75%',
      data: { mode: 0 }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.refreshManpower.emit();
      }
    });
  }

  addNewResource() {
    if(projectInfo.status == true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    if(projectInfo.can_update_task == false){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. You do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    const dialogRef = this.dialog.open(AddNewResourceDialogComponent, {
      width: '80%',
      // data: { name: this.name, selectedDate: this.today }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.refreshManpower.emit();
      }
    });
  }

  switchUser() {
    if(projectInfo.status == true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    if(projectInfo.status == true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    if(projectInfo.can_update_task == false){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. You do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    const dialogRef = this.dialog.open(SwitchUserDialogComponent, {
      width: '80%',
      // data: { name: this.name, selectedDate: this.today }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'success'){
        this.refreshManpower.emit();
      }
    });
  }
}
