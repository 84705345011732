import { Component, Inject, OnInit, DoCheck,  KeyValueDiffers, } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';

import { AppService } from '../../../app.service';
import * as projectInfo from '../../../globals';
import { count } from 'rxjs/operators';

@Component({
  selector: 'app-resource-management-dialog',
  styleUrls: ['./resource-management-dialog.component.scss'],
  templateUrl: './resource-management-dialog.component.html'
})
export class AddNewResourceDialogComponent implements OnInit {

  differ: any;
  taskList: Array<any> = [];
  employeeList: Array<any> = [];
  empListForTaskAllocation: Array<any> = [];
  restItems: any;
  apiResponse:  Array<any> = [];
  selectedEmployee: Array<any> = [];
  assignee: string;
  assignItems: Array<any> = [];
  allResourceData: Array<any> = [];
  undoSelectedItem: string;

  constructor(
    private differs: KeyValueDiffers,
    private appService: AppService,
    public dialogRef: MatDialogRef<AddNewResourceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.differ = this.differs.find({}).create();
    this.assignee = '';
  }

  ngOnInit() {
    console.log('================ employee List   =================');
    // this.spinner.show();
    const params = {'project_id': projectInfo.projectId };
    this.postRestItems('getTaskList', params)
    .subscribe(
      restItems => {
        console.log(restItems, ' =============== getTaskList List');
        // if (!restItems.success) {
        //   Swal('Oops...', 'Unable to fetch Employee List!', 'error');
        //   return;
        // }
       this.restItems = restItems;
       this.taskList = this.restItems.data;
       this.callEmployeeList();
        // this.spinner.hide();
      }
    );
  }

  ngDoCheck() {
    // check for object mutation
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        console.log('item changed In add new RRRRRRRRRResource', item.key);
        if (item.key === 'restItems') {
        //  this.taskList = this.restItems.data;
        }
      });
    }
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChangedHandler(date: Date) {
    this.data.selectedDate = date;
  }

  getSelectedItem(info) {
    this.selectedEmployee = info.item;
  }

  assignItem(event, i) {
    console.log('888888888888888888888888888888888888', i)
    console.log(this.empListForTaskAllocation);
    console.log(event);
    // if (!this.assignItems.length) {
    //   this.assignItems.push(event);
    // }
    // let foundElement = false;
    // (this.assignItems).forEach(element => {
    //   if (element.id === event.id) {
    //     element.hours = event.hours;
    //     foundElement = true;
    //   }
    // });
    // if (!foundElement) {
    //   this.assignItems.push(event);
    // }
    let counter = 0;
    this.allResourceData.forEach(function(obj) {
      if (counter++ === i) {
        let sub_counter = 0;
        let foundElement = false;
        (obj.task_id).forEach(element => {
          if (element === event.id) {
            obj.estimated_time[sub_counter] = event.hours;
            ++sub_counter;
            foundElement = true;
          }
        });
        if (!obj.task_id.length || !foundElement) {
          obj.estimated_time.push(event.hours);
          obj.task_id.push(event.id)
        }
    }
    });
    console.log('-------88888888888888--------', this.allResourceData);
  }

  deleteItem(id, i) {
    console.log('7777777777777777777777777777777777777777', i)
    // const toDelete = new Set([id]);
    // this.assignItems = this.assignItems.filter(obj => !toDelete.has(obj.id));
    this.undoSelectedItem = id;
    console.log('------7777777777777777---------', id);
    let counter = 0;
    this.allResourceData.forEach(function(obj) {
      if (counter++ === i) {
        let sub_counter = 0;
        // let foundElement = false;
        console.log('sub counter   :', sub_counter);
        (obj.task_id).forEach(element => {
          if (element === id) {
            console.log('found task id :', element)
            console.log('estimated time', obj.estimated_time)
            // const update_estimated_time = obj.estimated_time;
            // console.log('update_estimated_time', update_estimated_time)
            // update_estimated_time.slice(0, sub_counter);
            // console.log('update_estimated_time', update_estimated_time)
            // update_estimated_time.slice(0, 1+sub_counter);
            // console.log(1+sub_counter, 'sub_countersub_counter')
            // console.log('update_estimated_time', update_estimated_time)
            console.log('sice ans ', obj.estimated_time.splice(0, sub_counter + 1));
            obj.task_id.splice(0, sub_counter + 1);
            // foundElement = true;
          }
          ++sub_counter;
        });
        // if (!obj.task_id.length || !foundElement) {
        //   obj.estimated_time.push(event.hours);
        //   obj.task_id.push(event.id)
        // }
    }
    });
    console.log('-------############################--------', this.allResourceData);
  }

  callEmployeeList() {
    const params = {'project_id': projectInfo.projectId };
    this.postRestItems('getEmployeeList', params)
    .subscribe(
      restItems => {
        console.log(restItems, ' =============== employeee List');
        // if (!restItems.success) {
        //   Swal('Oops...', 'Unable to fetch Employee List!', 'error');
        //   return;
        // }
       this.restItems = restItems;
       this.employeeList = this.restItems.data;
        // this.spinner.hide();
      }
    );
  }

  recordData() {
    // console.log('---------------------------###################----------------------------')
    // console.log(this.assignee);
    // console.log(this.assignItems);
    // console.log('---------------------------###################----------------------------')

    // if (this.assignee === '' || this.assignItems.length === 0) {
    //   return;
    // }

    // const taskIds = [];
    // const hours = [];
    // this.assignItems.forEach(element => {
    //   taskIds.push(element.id);
    //   hours.push(element.hours);
    // });

    // const resourceAssignDetail = {
    //   'task_id' : taskIds,
    //   'project_id': projectInfo.projectId,
    //   'employee_id' : this.assignee,
    //   'estimated_time': hours
    // };
    // this.allResourceData.push(resourceAssignDetail);
  }

  onChange(event) {
    this.recordData();
    // this.assignee = event;
    if (!(this.empListForTaskAllocation.find(x => x.id === +event))) {
      const emp = this.employeeList.find(x => x.id === +event);
      emp.select = true;
      emp.items = [];
      this.empListForTaskAllocation.push(emp)
      this.allResourceData.push({
        'employee_id' : event,
        'task_id' : [],
        'estimated_time': []
      });
    }
    setTimeout(function(){
      document.getElementById('profile_task_block').scrollTop = 2500;
      document.getElementById('add_new_resource_modal').scrollTop = 2500;
    },500);
    console.log(this.allResourceData);
    console.log('ooooooooooooooooooooooooooooooooooo');
  }

  confirmSelection() {

    if (this.empListForTaskAllocation.length === 0) {
      Swal('Oops...', 'No allocation found!', 'error');
      return;
    }
    this.empListForTaskAllocation.forEach(element => {
      console.log(element, '---------------hehehehe---------------');
      if (element.items.length === 0) {
        Swal(
          'Oops...',
          'No hours assigned to ' + element.name,
          'error'
        );
        return;
      }
    });
    const param = {
      'project_id': projectInfo.projectId,
      'allocations': this.empListForTaskAllocation
    }
    
    this.appService.postSend('addMultipleManpowerToProject', param)
    .subscribe(
       restItems => {
        this.apiResponse.push(restItems);
         if(this.apiResponse[0].success === true){
            Swal(
              'Success!',
              this.apiResponse[0].message,
              'success'
            )
            this.dialogRef.close('success');
         }else{
            Swal(
              'Error!',
              this.apiResponse[0].message,
              'error'
            )
         }
       }
    );
  }

  removeEmpFromTaskAllocation(id) {
    const toDelete = new Set([id]);
    console.log(toDelete);
    this.empListForTaskAllocation = this.empListForTaskAllocation.filter(obj => !toDelete.has(obj.id));
    this.allResourceData = this.allResourceData.filter(obj => !toDelete.has(+obj.employee_id));
  }

  selectedItem($event, item) {
    console.log(this.allResourceData);
    for(let i = 0; i<this.empListForTaskAllocation.length; i++){
      if(this.empListForTaskAllocation[i].select == true){
        //now check if task is already allocted then ignore
        if(!this.empListForTaskAllocation[i].items.find(x => x.id === item.id)){
          this.empListForTaskAllocation[i].items.push({
            name : item.name,
            hours: 0,
            id: item.id
          })
        }
      }
    }
  }

  removeItem(empId, id, hours) {
    let employeeElement = this.empListForTaskAllocation.find(x => x.id === empId);
    if(employeeElement){
      let taskItem = employeeElement.items.find(x => x.id === id);
      if(taskItem){
        let index = employeeElement.items.indexOf(taskItem);
        employeeElement.items.splice(index,1);
        console.log(employeeElement);
      }
    }
  }

  assignHours(empId, id, hours) {
    let employeeElement = this.empListForTaskAllocation.find(x => x.id === empId);
    if(employeeElement){
      let taskItem = employeeElement.items.find(x => x.id === id);
      if(taskItem){
        //before assigning user hours check for rest of valdiations.
        if (hours <= 0) {
          Swal('Oops...', 'Assigning hours must be greater than zero!', 'error');
          return;
        }

        var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;


        //Before we will assign item to main component check for leaves and ask for confirmation.
        console.log(taskItem);
        let params = {
          project_id : projectInfo.projectId,
          due_date: [year, month, day].join('-'),
          assignee: [employeeElement.id],
          old_hours: 0,
          estimated_time: [hours]
        };

        this.appService.postSend('checkForHoursAllocationInProject', params)
      .subscribe(
        restItems => {
          if(restItems['success'] === true){
            //First check if user is on leave and ask for user confirmation.
            if(restItems['isOnLeaveDuringPlotting'] == true){
              Swal({
                title: 'Employee On Leave',
                text: 'Employee is on leave in between, hence plotting will be ignored on leave days and will carry forward to next days. Are you sure you want to plot employee?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
              }).then((result) => {
                if (result.value) {
                  taskItem.hours = hours;
                }else{
                  this.removeItem(empId, taskItem.id, hours);
                }
              });
            }else{
              taskItem.hours = hours;
              Swal(
                'success!',
                'User can be pllotted with mentioned hours. Please click on Add Resource, or select more resource from dropdown.',
                'success'
              )
            }
          }else{
            Swal(
              'Error',
              restItems['message'],
              'error'
            )
              this.removeItem(empId, taskItem.id, hours);
          }
        }
      );

      }
    }
  }
}
