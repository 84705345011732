import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const httpOptions = new HttpHeaders({
  'Content-Type':  'application/json',
  'Authorization': 'Bearer ya29.Gl2gBcgchtX-VDQERG6XUkJ1qJruwgkrvYvqUBQtiq3VFt9IVy5t2mlaqi95TAVZubHOZ5GwT0Wf33tTCrXNsJ8gUj2QYNBwtcJAUIpmTVPLzrG9zvp5-TsxPjrfRbw'
});

@Injectable()
export class AppApiInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('AppApiInterceptor was called');

    const clonedRequest = request.clone({
      headers: httpOptions
    });
    return next.handle(clonedRequest).pipe(map(event => {
      if (request.method === 'GET' && event instanceof HttpResponse) {
        console.log('Response Interceptor for GET', event);
        return event.clone({ body: event.body['posts']});
      }
      if (request.method === 'POST' && event instanceof HttpResponse) {
        console.log('Response Interceptor for POST', event);
        return event.clone({ body: event.body['posts']});
      }
    }));
  }
}
