'use strict';

export let projectId: String = '';
export let projectName: String = '';
export let sdate: String = '';
export let edate: String = '';
export let minFullDayHours: String = '';
export let minHalfDayHours: String = '';
export let allowToFill: String = '';
export let mandatoryHours: String = '';
export let timesheetWithoutPrevWeek: String = '';
export let draftMode: String = '';
export let status: Boolean = true;
export let can_create_task: Boolean = false;
export let can_update_task: Boolean = false;

// ToDo : need to add user Info
export function setProjectId(id: String) {
  projectId = id;
}

export function setProjectName(Name: String) {
  projectName = Name;
}

export function setStartDate(date: String) {
  sdate = date;
}

export function setEndDate(date: String) {
  edate = date;
}

export function setMinFullDayHours(value: String) {
  minFullDayHours = value;
}

export function setMinHalfDayHours(value: String) {
  minHalfDayHours = value;
}

export function setAllowToFill(value: String) {
  allowToFill = value;
}

export function setMandatoryHours(value: String) {
  mandatoryHours = value;
}

export function setTimesheetWithoutPrevWeek(value: String) {
  timesheetWithoutPrevWeek = value;
}

export function setDraft_mode(value: String) {
  draftMode = value;
}

export function setStatus(value: Boolean) {
  status = value;
}

export function setCanCreateTask(value: Boolean) {
  can_create_task = value;
}

export function setCanUpdateTask(value: Boolean) {
  can_update_task = value;
}