import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ManpowerComponent } from './manpower/manpower.component';
import { TimesheetComponent } from './timesheet/timesheet.component';
// import { HeroDetailComponent } from './hero-detail/hero-detail.component';

const routes: Routes = [
  // { path: '', redirectTo: '/newtimesheet', pathMatch: 'full' },
  { path: 'admin/newmanpower/:id', component: ManpowerComponent },
  // { path: 'detail/:id', component: HeroDetailComponent },
  { path: 'admin/newtimesheet', component: TimesheetComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
