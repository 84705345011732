import { Component, Inject, OnInit, KeyValueDiffers, ViewChild, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes
} from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerVisibilityService } from 'ng-http-loader';
import Swal from 'sweetalert2';

import { AppService } from '../../../app.service';
import * as projectInfo from '../../../globals';
import { CustomSwitchBlockComponent } from '../custom-switch-block';

@Component({
  selector: 'app-switch-user',
  styleUrls: ['./switch-user-management-dialog.component.scss'],
  templateUrl: './switch-user-management-dialog.component.html',
  animations: [
    trigger('switchState', [
      state('move', style({
        transform: 'translateX(-250%)',
      })),
      state('enlarge',   style({
        transform: 'scale(1.5)',
      })),
      state('spin',   style({
        transform: 'translateX(250%)',
      })),
      transition('* => *', animate('500ms ease')),
    ])
  ]
})
export class SwitchUserDialogComponent implements OnInit {

  differ: any;
  restItems: any;
  apiResponse:  Array<any> = [];
  taskId: string;
  employeeList: Array<any> = [];
  taskAssigneeDetails: Array<any> = [];
  assigneeList: Array<any> = [];
  isTaskSelected: boolean;
  modalReference: any;
  sourceItem: Array<any> = [];
  targetItem: Array<any> = [];
  dateDetail: any;
  currentStartDate: any;
  result: Array<any> = []

  @ViewChild('replaceHours') replaceHours: ElementRef;

  constructor(
    private modalService: NgbModal,
    private differs: KeyValueDiffers,
    private spinner: SpinnerVisibilityService,
    private appService: AppService,
    public dialogRef: MatDialogRef<SwitchUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.differ = this.differs.find({}).create();
    this.taskId = '';
    this.isTaskSelected = false;
    this.assigneeList = [];
    this.targetItem = [];
    this.sourceItem = [];
    this.currentStartDate = new Date();
    this.dateDetail = { 'sdate': '', 'edate': ''};
  }

  ngOnInit() {
    this.getTaskAssignee();
    const params = {'project_id': projectInfo.projectId };
    this.postRestItems('getEmployeeList', params)
    .subscribe(
      restItems => {
        this.restItems = restItems;
        this.employeeList = this.restItems.data;
      }
    );
  }

  getTaskAssignee() {

    const params = {
      'project_id': projectInfo.projectId,
      'task_id': '0' // i.e fetch all task assignee
    };
    this.appService.postSend('gettaskAssignee', params)
    .subscribe(
      restItems => {
        this.restItems = restItems;
        this.taskAssigneeDetails = this.restItems.data;
        console.log(restItems , '=============================gettaskAssignee')
      }
    );
  }

  ngDoCheck() {
    // check for object mutation
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        console.log('item changed In add new Resource', item.key);
        if (item.key === 'restItems') {
          this.employeeList = this.restItems.data;
        }
      });
    }
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChangedHandler(date: Date) {
    this.data.selectedDate = date;
  }

  onChange(taskId) {
    this.isTaskSelected = true;
    this.taskId = taskId;
    this.assigneeList = (this.taskAssigneeDetails).find(x => x.id === +(taskId)).assignee;
    // if (this.assigneeList === undefined) {
    //   this.assigneeList = [];
    // }
    console.log(this.assigneeList, '*********************##############***********************', taskId)
  }

  getSource(event) {
    event.state = 'stay';
    this.sourceItem[0] = event;
   console.log((this.sourceItem), '---------------getSource--------------------', event);
    if (this.targetItem.length !== 0) {
      this.modalReference = this.modalService.open(this.replaceHours,  { size: 'sm' });
    } else {
      Swal({
        position: 'top-end',
        type: 'info',
        title: 'Choose currently plotted resource to switch!',
        showConfirmButton: false,
        timer: 1500
      });
      // Swal('Require', 'Choose Target!', 'info');
    }
  }

  getTarget(event) {
    event.state = 'stay';
    this.targetItem[0] = event;
    console.log(this.targetItem[0], '---------------getTarget--------------------', event);
    if (this.sourceItem.length !== 0) {
      this.modalReference = this.modalService.open(this.replaceHours,  { size: 'sm' });
    } else {
      Swal({
        position: 'top-end',
        type: 'info',
        title: 'Choose available resource to switch!',
        showConfirmButton: false,
        timer: 1500
      });
      // Swal('Require', 'Choose Source!', 'info');
    }
  }

  switchUser() {
    const startDate = new Date(this.dateDetail.sdate + 'T00:00:00');
    const endDate = new Date(this.dateDetail.edate + 'T00:00:00');
    const projectStartDate = new Date(projectInfo.sdate + 'T00:00:00');
    const projectEndDate = new Date(projectInfo.edate + 'T00:00:00');
    let currentDate = new Date();
    if (startDate < projectStartDate || startDate > projectEndDate) {
      Swal('Oops...', `Start switch date must be in between ${projectInfo.sdate} and ${projectInfo.edate}!`, 'error');
      return null;
    } else if (endDate < projectStartDate || endDate > projectEndDate) {
      Swal('Oops...', `End switch date must be in between ${projectInfo.sdate} and ${projectInfo.edate}!`, 'error');
      return null;
    }

    if(startDate < currentDate){
      Swal('Oops...', 'Start date should be greater than current date', 'error');
      return null;
    }

    //this.sourceItem[0].state = (this.sourceItem[0].state === 'stay' ? 'move' : 'stay');
    //this.targetItem[0].state = (this.targetItem[0].state === 'stay' ? 'spin' : 'stay');
    console.log('*********************************************************')
    console.log(this.dateDetail, this.sourceItem, this.targetItem);
    console.log('*********************************************************')
    const params = {
      'changeEmpId': String(this.sourceItem[0].item.id),
      'task_id': this.taskId,
      'emp_id': String(this.targetItem[0].id),
      'project_id': projectInfo.projectId,
      'fromDate': this.dateDetail.sdate,
      'toDate': this.dateDetail.edate
    };
    console.log(this.sourceItem);
    console.log(this.targetItem);
    this.result.push(params);
    this.appService.postSend('switchTask', this.result)
    .subscribe(
      restItems => {
        this.apiResponse.push(restItems);
        if(this.apiResponse[0].success === true){
            this.dialogRef.close('success');
            this.modalReference.close();
            Swal(
              'Success!',
              this.apiResponse[0].message,
              'success'
            )
         }else{
            Swal(
              'Error!',
              this.apiResponse[0].message,
              'error'
            )
         }
      }, error => {
        this.sourceItem = [];
        this.targetItem = [];
      }
    );
  }

  animationStarted($event) {

  }

  animationDone($event, item) {

  }
}
