import { Component, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent {
  title = 'app';
  weekStartsOn: number = environment.weekStartsOn;
  standardDateFormat: string = environment.standardDateFormat;
  name: string;
  today: Date = new Date(Date.now());
  weekStartDate: Date;
  weekEndDate: Date;

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: { name: this.name, selectedDate: this.today }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
      this.today = result;
    });
  }

  getWeekStart(weekStartsAt) {
    // Mon = 1, Tus = 2, wed = 3, thus = 4, fri = 5 , Sat = 6, Sun = 0
    // console.log('Started day of week is ', weekStartsAt);

    const day  = this.today.getDay();
    const diff = this.today.getDate() - day + (day === 0 ? -6 : this.weekStartsOn);
    let weekStartedDate = new Date(this.today);
    weekStartedDate = new Date(weekStartedDate.setDate(diff));
    // console.log('Week start on : ', weekStartedDate);

    return weekStartedDate;
  }

  getWeekEnd(weekEndsAt) {
    // Mon = 1, Tus = 2, wed = 3, thus = 4, fri = 5 , Sat = 6, Sun = 0
    // console.log('End day of week is ', weekEndsAt);

    // const day  = this.today.getDay();
    // const diff = this.today.getDate() - day;
    // console.log('Week ends on : ', new Date(this.today.setDate(diff)));
    // return new Date(this.today.setDate(diff));
    const weekStartedDate = this.getWeekStart(1);
    const weekEndsDate = new Date(weekStartedDate.setDate(weekStartedDate.getDate() + 6));
    // console.log('Week ends on : ', weekEndsDate);
    return weekEndsDate;
  }

}

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: 'calendar-dialog.html',
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChangedHandler(date: Date) {
    this.data.selectedDate = date;
  }
}
