import { Component, Output, Inject, OnInit, DoCheck, KeyValueDiffers, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import {
//   trigger,
//   state,
//   style,
//   animate,
//   query,
//   stagger,
//   transition
// } from '@angular/animations';
// import { SpinnerVisibilityService } from 'ng-http-loader';
import Swal from 'sweetalert2';

import { AppService } from '../../../app.service';
import * as projectInfo from '../../../globals';

export enum STATUS_MODE {
  ADD_MODE    = 0,
  VIEW_MODE   = 1,
  UPDATE_MODE = 2,
}

@Component({
  selector: 'app-task-management-dialog',
  styleUrls: ['./task-management-dialog.component.scss'],
  templateUrl: './task-management-dialog.component.html'
})
export class AddNewTaskDialogComponent implements OnInit {

  title: string;
  employeeList: Array<any> = [];
  originnalEmployeeList: Array<any> = [];
  selectedEmployee: Array<any> = [];
  newTaskDetail: any;
  differ: any;
  restItems: any;
  isView: boolean;
  taskAssigneeDetails: Array<any> = [];
  apiResponse: Array<any> = [];
  assignItems: Array<any> = [];
  isUpdate: boolean;
  taskcode: string;
  undoSelectedItem: string;
  mode: number;
  defaultAssignItem: Array<any> = [];
  deleteDefaultAssignItem: Array<any> = [];

  constructor(
    private differs: KeyValueDiffers,
    // private spinner: SpinnerVisibilityService,
    private appService: AppService,
    public dialogRef: MatDialogRef<AddNewTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.taskcode = '';
    this.newTaskDetail = {
      'id': '',
      'title': '',
      'code': this.taskcode,
      'type': 'billable',
      'date': '',
      'summary': '',
      'desc': '',
      'is_chargeable': 1,
      'resource': this.selectedEmployee
    }
    this.differ = this.differs.find({}).create();
      this.isView = false;
      this.isUpdate = false;
    this.title = 'Add New Task';
    this.mode = STATUS_MODE.ADD_MODE;

    if(data.mode){
      this.mode = STATUS_MODE.VIEW_MODE;
    }
  }

  ngOnInit() {
    const params = {'project_id': projectInfo.projectId };
    this.postRestItems('getEmployeeList', params)
    .subscribe(
      restItems => {
        this.restItems = restItems;
        this.employeeList = this.restItems.data;
        this.originnalEmployeeList = this.restItems.data;
      }
    );
    this.generateTaskCode();
    if(this.mode === STATUS_MODE.VIEW_MODE){
      this.onModeChange(1, '');
    }
  }



  ngDoCheck() {
    // check for object mutation
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        console.log('item changed In add new Resource', item.key);
        if (item.key === 'restItems') {
          // this.employeeList = this.restItems.data;
        } else if (item.key === 'taskcode') {
          this.newTaskDetail.code = this.taskcode;
        } else if (item.key === 'apiResponse') {
          if (this.apiResponse[0].type === 'createTask' && this.apiResponse[1].success) {
            Swal(
              'success!',
              'Task created.',
              'success'
            )

            this.newTaskDetail = {
              'id': '',
              'title': '',
              'code': '',
              'type': '',
              'date': '',
              'summary': '',
              'desc': '',
              'is_chargeable': 1,
              'resource': this.selectedEmployee
            }
            this.dialogRef.close("success");
          } else if (this.apiResponse[0].type === 'createTask' && !this.apiResponse[1].success) {
            Swal(
              'error!',
              this.apiResponse[1].message,
              'error'
            )
          } else if (this.apiResponse[0].type === 'updateTask' && this.apiResponse[1].success) {
            Swal(
              'success!',
              'Task updated.',
              'success'
            )
            this.dialogRef.close("success");
          } else if (this.apiResponse[0].type === 'updateTask' && !this.apiResponse[1].success) {
            Swal(
              'error!',
              'Task not updated.',
              'error'
            )
          } else if (this.apiResponse[0].type === 'deleteTask' && this.apiResponse[1].success) {
            Swal(
              'success!',
              'Task deleted successfully.',
              'success'
            )
            const toDelete = new Set([this.apiResponse[2].id]);
            this.taskAssigneeDetails = this.taskAssigneeDetails.filter(obj => !toDelete.has(obj.id));
            this.dialogRef.close("success");
          } else if (this.apiResponse[0].type === 'deleteTask' && !this.apiResponse[1].success) {
            Swal(
              'Error!',
              'Task not deleted.',
              'error'
            )
          }
        }
      });
    }
  }

  generateTaskCode() {
    const params = {'project_id': projectInfo.projectId };
    this.postRestItems('generateTaskCode', params)
    .subscribe(
      restItems => {
        this.restItems = restItems;
        this.taskcode = this.restItems.taskcode;
      }
    );
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChangedHandler(date: Date) {
    this.data.selectedDate = date;
  }

  checkChargeability(value) {
    this.newTaskDetail.is_chargeable = value;
  }

  getSelectedItem(info) {
    if(this.newTaskDetail.date == ''){
      Swal('Oops...', 'Please select start date of task first.', 'error');
      return null;
    }
    console.log('===================================GETSELECTEDITEM===========================', this.selectedEmployee)
    this.selectedEmployee = info.item;
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    document.getElementById('add-task-scroll-to-block').scrollTop = 2500;
  }

  onModeChange(mode, item) {
    this.mode = mode;
    if (mode === STATUS_MODE.VIEW_MODE) {
        if(projectInfo.can_update_task == false){
          Swal({
            position: 'top-end',
            type: 'success',
            title: 'Action not allowed. You do not have enough rights to perform the action',
            showConfirmButton: false,
            timer: 5000
          })
          return;
        }
        this.isView = true;
        this.isUpdate = false;
      this.title = 'Task List';

      const params = {
        'project_id': projectInfo.projectId,
        'task_id': '0' // i.e fetch all task assignee
      };
      this.appService.postSend('gettaskAssignee', params)
      .subscribe(
        restItems => {
          this.restItems = restItems;
          this.taskAssigneeDetails = this.restItems.data;
          console.log(restItems , '=============================gettaskAssignee')
        }
      );
    } else if (mode === STATUS_MODE.ADD_MODE && item === '') {
      this.isView = false;
      this.isUpdate = false;
      this.title = 'Add New Task';
      this.assignItems = [];
      this.employeeList = this.originnalEmployeeList
    } else {
      console.log(item);
      this.newTaskDetail = {
        'id': item.id,
        'title': item.task_title,
        'code': item.taskcode,
        'type': item.type,
        'date': item.due_date,
        'summary': item.task_sumary,
        'desc': item.discription,
        'is_chargeable': item.is_chargeable,
        'project_id': projectInfo.projectId,
        'resource': item.assignee
      };
      this.isView = false;
      this.isUpdate = true;
      this.title = 'Update Task';
      this.taskcode = item.taskcode;
      this.selectedEmployee = item.assignee;
      this.defaultAssignItem = item.assignee;
      this.mode = STATUS_MODE.UPDATE_MODE;
      this.employeeList = this.originnalEmployeeList
    }
  }

  onChange(event) {
    // this.newTaskDetail.type =
    // console.log(event, 'eeeeeeeeeeeeeeeeeeee', this.newTaskDetail.type);
  }

  assignItem(event) {
    let foundElement = false;
    
    if (this.mode == STATUS_MODE.UPDATE_MODE) {
      if(this.defaultAssignItem){
        (this.defaultAssignItem).forEach(element => {
            if (element.id === event.id) {
               foundElement = true;
            }
        });
      }
    }else{
      (this.assignItems).forEach(element => {
          if (element.id === event.id) {
             foundElement = true;
          }
      });
    }
    if(foundElement == false){
      this.assignItems.push(event);
    }

    foundElement = false;
    (this.assignItems).forEach(element => {
      if (element.id === event.id && this.mode !== STATUS_MODE.UPDATE_MODE) {
        element.hours = event.hours;
        foundElement = true;
      }
    });
    if (!foundElement && this.mode !== STATUS_MODE.UPDATE_MODE ) {
      //this.assignItems.push(event);
    } else {
      (this.defaultAssignItem).forEach(element => {
        if (element.id === event.id && this.mode !== STATUS_MODE.UPDATE_MODE) {
          element.hours = event.hours;
        }
      });
    }
  }

  deleteItem(id) {
    console.log('**********************DELETE********************************', id , this.mode)
    const toDelete = new Set([id]);
    this.assignItems = this.assignItems.filter(obj => !toDelete.has(obj.id));
    this.undoSelectedItem = id;
    /*if (this.mode === STATUS_MODE.UPDATE_MODE) {
      console.log('**********************DELETE********************************', id)
      this.defaultAssignItem = this.defaultAssignItem.filter(obj => !toDelete.has(obj.id));
      this.deleteDefaultAssignItem.push(id);
    }*/
  }

  recordData() {
    const selectedDate = new Date(this.newTaskDetail.date + 'T00:00:00');
    const projectStartDate = new Date(projectInfo.sdate + 'T00:00:00');
    const projectEndDate = new Date(projectInfo.edate + 'T00:00:00');

    if (this.newTaskDetail.title === '') {
      Swal('Oops...', 'No TITLE Found!', 'error');
      return null;
    } else if (String(this.newTaskDetail.code) !== this.taskcode) {
      Swal('Oops...', 'Task code does not match with server!', 'error');
      return null;
    } else if (this.newTaskDetail.type === '') {
      Swal('Oops...', 'No type Found!', 'error');
      return null;
    } else if (this.newTaskDetail.date === '') {
      Swal('Oops...', 'No date Found!', 'error');
      return null;
    } else if (selectedDate < projectStartDate || selectedDate > projectEndDate) {
      Swal('Oops...', `Task date must be in between ${projectInfo.sdate} and ${projectInfo.edate}!`, 'error');
      return null;
    } else if (this.newTaskDetail.summary === '') {
      Swal('Oops...', 'No summary Found!', 'error');
      return null;
    } else if (this.newTaskDetail.desc === '') {
      Swal('Oops...', 'No description Found!', 'error');
      return null;
    }


    if (this.mode === STATUS_MODE.ADD_MODE) {
      const taskIds = [];
      const hours = [];

      if (this.assignItems.length === 0) {
        Swal('Oops...', 'No Assignee Found!', 'error');
        return null;
      }

      this.assignItems.forEach(element => {
        taskIds.push(element.id);
        hours.push(element.hours);
      });

      return {
        'project_id': projectInfo.projectId,
        'task_id': this.newTaskDetail.id,
        'type': this.newTaskDetail.type,
        'due_date': this.newTaskDetail.date,
        'task_summary': this.newTaskDetail.summary,
        'task_code': this.newTaskDetail.code,
        'is_chargeable': this.newTaskDetail.is_chargeable,
        'task_related_to': this.newTaskDetail.related_to,
        'description': this.newTaskDetail.desc,
        'task_title': this.newTaskDetail.title,
        'assignee': taskIds,
        'estimated_time': hours
      };
    } else if (this.mode === STATUS_MODE.UPDATE_MODE) {
      const taskIds = [];
      const hours = [];
      this.assignItems.forEach(element => {
          taskIds.push(element.id);
          hours.push(element.hours);
      });

      return{
        'project_id': projectInfo.projectId,
        'task_id': this.newTaskDetail.id,
        'type': this.newTaskDetail.type,
        'due_date': this.newTaskDetail.date,
        'task_summary': this.newTaskDetail.summary,
        'task_code': this.newTaskDetail.code,
        'is_chargeable': this.newTaskDetail.is_chargeable,
        'task_related_to': this.newTaskDetail.related_to,
        'description': this.newTaskDetail.desc,
        'task_title': this.newTaskDetail.title,
        'added_assignee': {
          'assignee': taskIds,
          'estimated_time': hours
        }
      };
    }

  }

  addNewTask() {

    const params = this.recordData();
    console.log('====================== CREATE TASK===================' , params);
    if (params === null) {
      console.log('====================== CREATE TASK IF===================' , params);
      return;
    } else {
      console.log('====================== CREATE TASK ELSE ===================' , params);
      this.appService.postSend('createTaskInProject', params)
      .subscribe(
        restItems => {
          console.log('====================== CREATE TASK===================', restItems );
          const apiResponse = [];
          apiResponse.push({type: 'createTask'});
          apiResponse.push(restItems);
          this.apiResponse = apiResponse;
        }
      );
    }
  }

  updateTask() {
    const params = this.recordData();
    console.log(params);
    if (params != null) {
      this.appService.postSend('updateTask', params)
      .subscribe(
        restItems => {
          const apiResponse = [];
          apiResponse.push({type: 'updateTask'});
          apiResponse.push(restItems);
          this.apiResponse = apiResponse;
          console.log('====================== UPDATE TASK===================', restItems );
        }
      );
    }
  }

  deleteTask(id) {
    Swal({
      title: 'Are you sure?',
      text: 'You want to delete this task!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.value) {
        const params = { 'task_id': id };
        this.appService.postSend('delete_task', params)
        .subscribe(
          restItems => {
            const apiResponse = [];
            apiResponse.push({type: 'deleteTask'});
            apiResponse.push(restItems);
            apiResponse.push({id: id});
            this.apiResponse = apiResponse;
            console.log('====================== DELETE TASK===================', restItems );
          }
        );
      }
    });
  }

  onEmployeeSearch(value){
    if(value.length >= 3){
      this.employeeList = this.originnalEmployeeList.filter(obj => obj.name.toLowerCase().indexOf(value) !== -1);
    }else{
      this.employeeList = this.originnalEmployeeList;
    }
  }
}
