// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  weekStartsOn: 1,
  APIRequiredDateFormat: 'yyyy-M-d',
  standardDateFormat: 'yyyy-MM-dd',
  halfDayWorkingHours: '4:30',
  totalWorkingHours: '9:00',
  production: false
};
