import {
  Component,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  KeyValueDiffers,
  ViewEncapsulation
} from '@angular/core';
import { AppService } from '../../app.service';
import * as projectInfo from '../../globals';


@Component({
  selector: 'app-manpower-report',
  templateUrl: './manpower-report.component.html',
  styleUrls: ['./manpower-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManpowerReportComponent {
  title: String;
  differ: any;
  currentPage: number;
  data: any = [];
  rowdes: Array<any> = [];
  scrollCount: Array<any> = [];
  headerCol: Array<any> = [];
  accordionClass: Array<any> = [];
  scrollCallback;

  @ViewChild('manpowerReport') manpowerReport: ElementRef;
  @Output() reportToPlotting: EventEmitter<any> = new EventEmitter();

  constructor(
    private differs: KeyValueDiffers,
    private appService: AppService
  ) {
    this.title = 'Manpower Resource Pool';
    this.differ = this.differs.find({}).create();
    this.currentPage = 0;
    this.scrollCallback = this.getStories.bind(this);
  }

  ngDoCheck() {
    // check for object mutation
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        if (item.key === 'data') {
          //if (this.currentPage === 1) {
            this.rowdes = this.data.records;
          //}
          this.headerCol = this.headerCol.concat(this.data.header);
          this.scrollCount.push(this.data.records);
          (this.data.records).forEach(element => {
            this.accordionClass.push('expand');
          });
        }
      });
    }
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }

  statusBaseColorCode(status) {
    switch (status) {
      case 'u':
        return '#95d9f0';

      case 'available':
        return '#88ca92';

      case 'not_available':
        return '#ef6262';

      case 'w':
      case 'h':
        return '#ccc';

      case 'l':
        return '#BDA5D7';

      case 'd':
        return '#303030';

      default:
        return '#ffdc7a';
    }
  }

  moveToPlotting(event, employee, designation) {
    employee.designation =  designation;
    this.reportToPlotting.emit({ event: event, employee: employee});
  }

  doRefresh() {
    this.scrollCount = [];
    this.headerCol = [];
    this.currentPage = 0;
    const params = {'page': 0};

    this.appService.postSend('getManpowerAvailability', params)
   .subscribe(
      restItems => {
        this.currentPage++;
        this.data = restItems;
      }
    );
  }

  // *********************** Service Call ********************* //
  designationToggle(num) {
    const row = this.manpowerReport.nativeElement.getElementsByClassName('row' + num);

    for (let i = 0; i < row.length; i++) {
      if (row[i].classList.contains('collapse')) {
        row[i].classList.remove('collapse');
        row[i].classList.add('expand');
        this.accordionClass[num] = 'expand';
      } else {
        row[i].classList.remove('expand');
        row[i].classList.add('collapse');
        this.accordionClass[num] = 'collapse';
      }
    }
  }

  getStories() {
    const params = {'page': this.currentPage++};
    return this.appService.postSend('getManpowerAvailability', params).do(this.processData);
  }

  private processData = (data) => {
    this.data = data;
  }
}
