import {
  Component,
  Input,
  Output,
  DoCheck,
  KeyValueDiffers,
  EventEmitter
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes
} from '@angular/animations';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-custom-list',
  template: `
    <style>
      .custom-list
      {
      float: left;
      background: #f2f2f2;
      width: 100%;
      max-height: 310px;
      overflow-y: auto;
      overflow-x: hidden;
      }
      .custom-list table {
      width: 100%;
      }
      .emp_name {
        padding-top: 10px;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .custom-list table tbody tr {
        border: 1px solid #F5C4C6;
      }
      .add_new_resource_tasks_list .emp_name {
          color: #fff;
      }

      .custom-list table td {
        height: 50px; !important;
      }
      .employee_lists_title {
        color: #FE5E62;
      }
    </style>
    <div class="custom-list" style="height:270px;overflow-y:scroll">
      <table>
        <tr *ngFor="let item of items; let i = index" #panel{{i}}
        [@assigneeItem]='item.state'
        (@assigneeItem.start)="animationStarted($event)"
        (@assigneeItem.done)="animationDone($event, item, i)"
        [ngStyle]="{ 'padding-bottom': item==targetItem ? itemPadding + 'px' : '5px' }" >
          <td (click)="selectedItem($event, item)">
            <div class="row">
              <div class=" col-xs-2">
                <img *ngIf="item.img" src="{{item.img}}" width="40" height="40">
              </div>
              <div class="col-xs-8">
                <span class="emp_name" title="{{item.name}}">{{item.name}}-{{item.designation}}</span>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  `,
  animations: [
    trigger('assigneeItem', [
      state('stay', style({
        // transform: 'translateX(-100%)',
        opacity: 1,
      })),
      state('move', style({
        opacity: 0,
        // transform: 'scale(1.2)',
      })),
      transition('stay => move',
        animate('2000ms', keyframes([
          style({
            transform: 'translateX(0)  translateY(0)  rotateY(0)',
            offset: 0
          }),
          style({
            transform: 'translateX(-300px) translateY(300px) rotateY(90deg)',
            offset: 0.33
          }),
          style({
            transform: 'translateX(-300px) translateY(300px) rotateY(180deg)',
            offset: 0.66
          }),
          style({
            transform: 'translateX(-300px) translateY(300px)',
            offset: 1.0
          })
        ]))
      ),
    ]),
  ]
})
export class CustomListComponent {
  @Input() items: Array < any > = [];
  @Input() isAllowedToAnimate: boolean;
  @Input() defaultItem: Array<any> = [];
  @Input() undoSelectedItem: string;
  @Output() getSelectedItem: EventEmitter < any > = new EventEmitter();

  selection: boolean;
  differ: any;

  constructor(
    private differs: KeyValueDiffers,
  ) {
    this.selection = false;
    this.isAllowedToAnimate = true;
    this.differ = this.differs.find({}).create();
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        console.log('item changed In custom list', item.key);
        if (item.key === 'undoSelectedItem') {
          this.items.find(x => x.id === this.undoSelectedItem).state = 'stay';
         }
      });
    }
  }

  selectedItem($event, item) {
    console.log('custom list is clicked', this.items, typeof(this.items));
    if(item.state == 'new'){
      item.state = 'stay';
      return null;
    }
    if(this.defaultItem['date'] == ''){
      Swal('Oops...', 'Please select start date of task first.', 'error');
      return null;
    }
    if (this.isAllowedToAnimate) {
      item.state = (item.state === 'stay' ? 'move' : 'stay');
      this.selection = true;
    } else {
      this.getSelectedItem.emit({
        'event': $event,
        'item': item
      });
    }
  }

  animationStarted($event) {
    if (this.selection) {
      console.log('start ', this.selection);
    }
  }

  animationDone($event, item, i) {
    if (this.selection && item.state === 'move') {
      this.getSelectedItem.emit({
        'event': $event,
        'item': item,
        'pos': i
      });
    }
  }
}
