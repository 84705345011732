import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable()
export class AppService {

  protected url: String = 'https://app.ardentadvisory.com/';
  //protected url: String = 'http://localhost:8888/timesheetbackend/public/';

  constructor(
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    private spinner: SpinnerVisibilityService
  ) {}

  private showLoader(urlparam, method): void {
    console.log(`${method} Method Show Loader for ${urlparam}`);
    if(urlparam == 'updateProjectManpower' || urlparam == 'getManpowerAvailability'){
      return;
    }
    this.spinner.show();
  }

  private hideLoader(): void {
    console.log('Hide loader');
    this.spinner.hide();
  }

  private onEnd(): void {
    this.hideLoader();
  }
  // Rest Items Service: Read all REST Items
  getAll(urlparam, param) {
    this.showLoader(urlparam, 'GET');
    return this.http
      .get<any[]>(this.url + urlparam, {
        // headers: HttpHeader,
        params: param
      })
      .pipe(map(data => data))
      .finally(() => {
        this.onEnd();
    });
  }

  postSend(urlparam, param) {
    this.showLoader(urlparam, 'POST');
    return this.http
      .post<any[]>(this.url + urlparam,
         param
      )
      .pipe(map(data => data))
      .finally(() => {
        this.onEnd();
    });
  }
}
