import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

import { Observable, Subscription } from 'rxjs/Rx';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/startWith';

interface ScrollPosition {
  sW: number;
  sR: number;
  cW: number;
};

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
  sW: 0,
  sR: 0,
  cW: 0
};

@Directive({
  selector: '[appInfiniteScroller]'
})
export class InfiniteScrollerDirective implements AfterViewInit {

  private scrollEvent$;

  // private userScrolledDown$;
  private userScrolledRight$;
  private requestStream$;

  private requestOnScroll$;

  @Input()
  scrollCallback;

  @Input()
  immediateCallback;

  @Input()
  scrollPercent = 70;

  constructor(private elm: ElementRef) { }

  ngAfterViewInit() {

    this.registerScrollEvent();

    this.streamScrollEvents();

    this.requestCallbackOnScroll();

  }

  private registerScrollEvent() {

    this.scrollEvent$ = Observable.fromEvent(this.elm.nativeElement, 'scroll');

  }

  private streamScrollEvents() {
    this.userScrolledRight$ = this.scrollEvent$
      .map((e: any): ScrollPosition => ({
        sW: e.target.scrollWidth,
        sR: e.target.scrollLeft,
        cW: e.target.clientWidth
      }))
      .pairwise()
      .filter(positions => this.isUserScrollingRight(positions) && this.isScrollExpectedPercent(positions[1]))
  }

  private requestCallbackOnScroll() {
    this.requestOnScroll$ = this.userScrolledRight$;

    if (this.immediateCallback) {
      this.requestOnScroll$ = this.requestOnScroll$
        .startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION]);
    }

    this.requestOnScroll$
      .exhaustMap(() => {
        return this.scrollCallback();
      })
      .subscribe((data) => { console.log(data) }, (err) => console.log(err));

  }

  /**
    check if the user is scrolling right by
    previous scroll position and current scroll position
  **/
  private isUserScrollingRight = (positions) => {
    // console.log(positions[0].sR , ' <<<<<<<<<<<<<<<<<  ' , positions[1].sR);
    return positions[0].sR < positions[1].sR;
  }

  private isScrollExpectedPercent = (position) => {
    // console.log(((position.sR + position.cW) / position.sW) > (this.scrollPercent / 100), " ------------------");
    return ((position.sR + position.cW) / position.sW) > (this.scrollPercent / 100);
  }

}
