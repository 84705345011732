import { Component, Input, Output, EventEmitter, ViewEncapsulation  } from '@angular/core';

@Component({
  selector: 'app-custom-switch-block',
  template: `
    <style>
      .switch-block
      {
        float: left;
        background: white;
        border: thin solid blue;
        margin: 0 20px;
        width: 300px;
        height: 300px;
        line-height: 300px;
      }

      app-custom-list table tr {
        height: 50px;
      }
    </style>
    <div class="row">
      <div class="col-md-2 text-center employee_img_name switch_user_custom_fields"
      *ngFor="let item of items;"
      (click)="selectTarget(item)">
          <div class="card" id={{item.id}}>
            <img class="card-img-top employee_img" src="{{item.img}}" alt="Card image cap"/>
            <div class="card-body">
              <span>{{item.name}}</span>
              <p>{{item.designation}}</p>
            </div>
          </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None
})

export class CustomSwitchBlockComponent {
    @Input() data:  any;
    @Input() items:  any;
    @Output() getTarget: EventEmitter <any> = new EventEmitter();

    constructor() {}

    getName() {
        // console.log('getData', this.data.name);
        return this.data ?  this.data.name : 'XXX';
    }

    getDesigination() {
        console.log('getData', typeof(this.data));
        return 'Developer';
    }

    getImg() {
        // console.log('getData', this.data.img);
       return this.data ? this.data.img : '../assets/imgs/profile_pic1.jpg';
    }

    addData(obj: any) {
        this.data = obj;
    }

    removeData(obj: any): boolean {
        this.data = null;
        return true;
    }

    selectTarget(target) {
      this.getTarget.emit(target);
    }
}
