import { Component, OnInit,  enableProdMode, ViewEncapsulation, KeyValueDiffers  } from '@angular/core';
import { AppService } from './app.service';
import * as projectSetting from './globals';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  differ: any;
  apiResponse: Array<any> = [];

  constructor(
    private differs: KeyValueDiffers,
    private appService: AppService
  ) {
    this.differ = this.differs.find({}).create();
  }

  ngOnInit() {
    this.appService.getAll('userInfo', '')
    .subscribe(
      restItems => {
        // ToDo: Need to add User info in globals
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', restItems);
      }
    );
    this.callTimesheetSettingsList();
  }

  ngDoCheck() {
    const change = this.differ.diff(this);

    if (change) {
      change.forEachChangedItem(item => {
        console.log('timesheetSettings changed', item.key);
        if (item.key === 'apiResponse') {
          if (this.apiResponse[0].type === 'timesheetSettings' && this.apiResponse[1].success) {

            const settings = this.apiResponse[1].timesheetSettings;

            if (settings[0].key === 'min_full_day_hours') {
              projectSetting.setMinFullDayHours(settings[0].value)
            }
            if (settings[1].key === 'min_half_day_hours') {
              projectSetting.setMinHalfDayHours(settings[1].value)
            }
            if (settings[2].key === 'allow_to_fill') {
              projectSetting.setAllowToFill(settings[2].value)
            }
            if (settings[3].key === 'mandatory_hours') {
              projectSetting.setMandatoryHours(settings[3].value)
            }
            if (settings[4].key === 'can_fill_current_timesheet_without_previous_week') {
              projectSetting.setTimesheetWithoutPrevWeek(settings[4].value)
            }
            if (settings[5].key === 'draft_mode') {
              projectSetting.setDraft_mode(settings[5].value)
            }
          }
        }
      });
    }
  }

  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  callTimesheetSettingsList() {
    this.appService.getAll('admin/timesheetSettingsList', '')
    .subscribe(
      restItems => {
        const apiResponse = [];
        apiResponse.push({type: 'timesheetSettings'});
        apiResponse.push(restItems);
        this.apiResponse = apiResponse;
      }
    );
  }
}
