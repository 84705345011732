import {
  Component,
  Directive,
  OnInit,
  AfterViewInit,
  ViewChild, ElementRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

@Component({
  selector: 'app-week-calendar',
  templateUrl: './week-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./week-calendar.component.scss']
})
export class WeekCalendarComponent implements OnInit {

  view: String = 'month';

  viewDate: Date = new Date();

  events: CalendarEvent[] = [];

  // @ViewChild('calweekend', {read: ElementRef}) calweekend: ElementRef;

  @Output() dateChanged: EventEmitter<Date> =   new EventEmitter();

  constructor() { }

  ngOnInit() {
    // outputs `I am span`
    // this.calweekend.nativeElement.textContent
    // this.calweekend.nativeElement.querySelector('.cal-weekend')
    // this.calweekend.nativeElement.innerHTML
    // console.log(this.calweekend.nativeElement.querySelector('mwl-calendar-month-view'));
  }

  getSelectDate($event) {
    this.dateChanged.emit($event.day.date);
    this.viewDate = $event.day.date;
    console.log('---------------------getSelectDate DATE-----------------', $event.day.date)
    console.log('---------------------getSelectDate INMONTH-----------', $event.day.inMonth)
    // classList.add
    const calendarCell = document.getElementsByTagName('mwl-calendar-month-cell');
    // console.log(typeof(calendarCell));

    Object.keys(calendarCell).forEach(key => {
      const value = calendarCell[key];
      console.log(value.innerText);
      // console.log(`key is ${key} and value is ${value}`);
    });
  }

  selectFrom(viewDate) {
   // console.log(viewDate , ' =====================  selectFrom(viewDate) ===========', viewDate.path);
  }

  selectTo(viewDate) {
    // console.log(viewDate , ' =====================  selectTo(viewDate) ===========');
    // srcElement > innerText type: mousedown
  }

  handleDayClick($event, day) {
    console.log('handleDayClick   =  ', $event, day)
    // console.log('#########################', $event.day.date);
  }
}
