import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  DoCheck,
  EventEmitter,
  KeyValueDiffers,
  HostListener,
  ViewEncapsulation
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { MatDialog } from '@angular/material';
import { AppService } from '../../app.service';
import * as projectInfo from '../../globals';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

export enum KEY_CODE {
  ENTER_PRESS = 13,
  TAB_PRESS = 9,
  ESC_PRESS = 27
}
export enum CURRENT_MODE {
  NONE                 = 0,
  SINGLE_EDITING_HOURS = 1,
  MULTI_EDITING_HOURS  = 2,
  SHIFTING_HOURS_MODE  = 3,
}

@Component({
  selector: 'app-manpower-plotting',
  templateUrl: './manpower-plotting.component.html',
  styleUrls: ['./manpower-plotting.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate(100)
      ]),
      transition('* => leave', [
        animate(100, style({transform: 'translateX(100%)'}))
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class ManpowerPlottingComponent {
  title: String = '';
  sdate: String = '';
  edate: String = '';
  differ: any;
  stopProcess: boolean;
  projectStatus: String = '';
  budgetStatus: String = '';
  currentPage: number;
  projectData: any = [];
  headerCol: Array<any> = [];
  scrollCount: Array<any> = [];
  accordionClass: Array<any> = [];
  scrollCallback;
  selectedTaskRow: number;
  selectionStartFrom: number;
  newHours: number;
  currentStartDate: any;
  currentEndDate: any;
  isDateRangeSelected: boolean;
  isReachedAtEnd: boolean;
  oldValue: number;
  oldStatus: string;
  closeResult: string;
  empId: number;
  mid: Array<any> = [];
  values: Array<any> = [];
  apiResponse: Array<any> = [];
  refresh: boolean;
  isShifting: boolean;
  selection: boolean;
  nextElementMid: Array<any> = [];
  isClear: boolean;
  disputeData: Array<any> = [];
  mode: number;
  hoursStatus: string;
  modalRef: any;
  _initManpowerRefresh: boolean;

  @ViewChild('accordion') accordion: ElementRef;
  @ViewChild('replaceHours') replaceHours: ElementRef;
  @ViewChild('disputeInfo') disputeInfo: ElementRef;
  @Input() plotterList: Array<any> = [];
  @Input() items: Array<any> = [];
  @Input()
  set initManpowerRefresh(initManpowerRefresh: boolean) {
    this._initManpowerRefresh = initManpowerRefresh;
  }
  @Output() manpowerRefresh: EventEmitter<any> = new EventEmitter();

  constructor(
    private differs: KeyValueDiffers,
    private appService: AppService,
    public dialog: MatDialog,
    private modalService: NgbModal
  ) {
    this.differ = this.differs.find({}).create();
    this.currentPage = 0;
    this.refresh = false;
    this.isShifting = false;
    this.selection = true;
    this.stopProcess = false;
    this.isClear = false;
    this.projectData = {
      "info": {
        "sdate": "",
        "edate": ""
      }
    };
    this.currentEndDate = new Date();
    this.currentStartDate = new Date();
    this.mode = CURRENT_MODE.NONE;
    this.scrollCallback = this.getStories.bind(this);
    this.isReachedAtEnd = false;
  }

  ngDoCheck() {
    // check for object mutation
    const change = this.differ.diff(this);
    console.log('its is changed ');
    if (change) {
      change.forEachChangedItem(item => {
        console.log('item changed In manpower Project', item.key);
        if(item.key === '_initManpowerRefresh'){
          this.doRefresh();
        }
        else if (item.key === 'projectData') {
          const getProjectInfo = this.projectData.info;
          projectInfo.setProjectId(getProjectInfo.id);
          projectInfo.setProjectName(getProjectInfo.projectname);
          projectInfo.setStartDate(getProjectInfo.sdate);
          projectInfo.setEndDate(getProjectInfo.edate);
          projectInfo.setStatus(this.projectData.readonly);
          projectInfo.setCanCreateTask(this.projectData.taskRights.can_create);
          projectInfo.setCanUpdateTask(this.projectData.taskRights.can_update);
          this.title = projectInfo.projectName;
          this.sdate = getProjectInfo.sdate;
          this.edate = getProjectInfo.edate;
          this.currentStartDate = getProjectInfo.sdate;
          this.currentEndDate = getProjectInfo.edate;
          this.projectStatus = this.projectData.projectStatus;
          this.budgetStatus = this.projectData.budgetStatus.replace('_',' ');
          if ((!this.refresh) && (this.projectData.header.length !== 0)) {
            if(this.scrollCount.length == 0){
              this.scrollCount.push(this.projectData.emp);
            }else{
              //Now loop through each response and conact it with all the employees
              for(let i = 0 ; i<this.scrollCount[0].length ; i++){
                for(let j = 0;j<this.projectData.emp.length; j++){
                  if(this.projectData.emp[j].id == this.scrollCount[0][i].id){
                    //concat summary.hours
                    this.scrollCount[0][i].summary.hours = this.scrollCount[0][i].summary.hours.concat(this.projectData.emp[j].summary.hours);

                    for(let k = 0 ; k < this.projectData.emp[j].records.length ; k++){
                      //concat records.hours
                      this.scrollCount[0][i].records[k].hours = this.scrollCount[0][i].records[k].hours.concat(this.projectData.emp[j].records[k].hours);

                      //concat records.manpowerid
                      this.scrollCount[0][i].records[k].manpowerid = this.scrollCount[0][i].records[k].manpowerid.concat(this.projectData.emp[j].records[k].manpowerid);
                    }
                  }
                }
              }
            }
            console.log(this.scrollCount);
            this.headerCol = this.headerCol.concat(this.projectData.header);

            (this.scrollCount[0]).forEach(element => {
              this.accordionClass.push('expand');
            });
          } else if (this.refresh) {
            this.scrollCount = [];
            this.headerCol = [];
            if(this.scrollCount.length == 0){
              this.scrollCount.push(this.projectData.emp);
            }else{
              //Now loop through each response and conact it with all the employees
              for(let i = 0 ; i<this.scrollCount[0].length ; i++){
                for(let j = 0;j<this.projectData.emp.length; j++){
                  if(this.projectData.emp[j].id == this.scrollCount[0][i].id){
                    //concat summary.hours
                    this.scrollCount[0][i].summary.hours = this.scrollCount[0][i].summary.hours.concat(this.projectData.emp[j].summary.hours);

                    for(let k = 0 ; k < this.projectData.emp[j].records.length ; k++){
                      //concat records.hours
                      this.scrollCount[0][i].records[k].hours = this.scrollCount[0][i].records[k].hours.concat(this.projectData.emp[j].records[k].hours);

                      //concat records.manpowerid
                      this.scrollCount[0][i].records[k].manpowerid = this.scrollCount[0][i].records[k].manpowerid.concat(this.projectData.emp[j].records[k].manpowerid);
                    }
                  }
                }
              }
            }
            console.log(this.scrollCount);
            this.headerCol = this.headerCol.concat(this.projectData.header);
            this.refresh = false;

            if (this.mode === CURRENT_MODE.SINGLE_EDITING_HOURS) {
              this.triggerFalseDBLClick(this.nextElementMid, false)
            }
          } else if (this.projectData.header.length === 0) {
            /*Swal(
              'Info!',
              `Project Due Date of ${projectInfo.projectName} is expired!`,
              'info'
            )*/
            this.isReachedAtEnd = true;
          }
          // this.spinner.hide();
        } else if (item.key === 'apiResponse') {
          if (this.apiResponse[0].type === 'deleteHours' && this.apiResponse[1].success) {
            Swal({
              position: 'top-end',
              type: 'success',
              title: 'Your hours has been deleted.',
              showConfirmButton: false,
              timer: 1500
            });
            // this.modalRef.close();
            this.doRefresh();
          } else if (this.apiResponse[0].type === 'deleteHours' && !this.apiResponse[1].success) {
            Swal(
              'Deleted!',
              'Your hours has not been deleted.',
              'success'
            )
            this.doRefresh();
          } else if (this.apiResponse[0].type === 'updateHours' && this.apiResponse[1].success) {
            /*Swal({
              position: 'top-end',
              type: 'success',
              title: 'Your hours has been Updated.',
              showConfirmButton: false,
              timer: 1500
            });*/

            // this.modalRef.close();
          } else if (this.apiResponse[0].type === 'updateHours' && !this.apiResponse[1].success) {
            Swal(
              'Updated!',
              'Your hours has not been Updated.',
              'success'
            )
            this.doRefresh();
          } else if (this.apiResponse[0].type === 'allocateManPowerToProject' && this.apiResponse[1].success) {
            Swal({
              position: 'top-end',
              type: 'success',
              title: 'Manpower Resource is allocated to Project.',
              showConfirmButton: false,
              timer: 1500
            });
            this.doRefresh();
          } else if (this.apiResponse[0].type === 'allocateManPowerToProject' && !this.apiResponse[1].success) {
            Swal(
              'Error!',
              this.apiResponse[1].message,
              'error'
            )
            this.doRefresh();
          } else if (this.apiResponse[0].type === 'deleteTask' && this.apiResponse[1].success) {
            Swal({
              position: 'top-end',
              type: 'success',
              title: this.apiResponse[1].message,
              showConfirmButton: false,
              timer: 1500
            });
            this.doRefresh();
          } else if (this.apiResponse[0].type === 'disputeData' && this.apiResponse[1].success) {
            // this.spinner.hide();
            this.disputeData = this.apiResponse[1].data;
            this.modalService.open(this.disputeInfo,  { size: 'sm' });
            this.doRefresh();
            return;
          }

        } else if (item.key === 'scrollCount') {

        } else if(item.key === 'currentStartDate' && this.isDateRangeSelected === true){
          this.loadProjectManpowerBetweenDates();
          this.isDateRangeSelected = true;
        } else if(item.key === 'currentEndDate'  && this.isDateRangeSelected === true){
          this.loadProjectManpowerBetweenDates();
          this.isDateRangeSelected = true;
        }else if(item.key === 'isDateRangeSelected'  && this.isDateRangeSelected === true){
          this.loadProjectManpowerBetweenDates();
        }

      });
    }
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }
  openModal(content): void {
    this.modalRef  = this.modalService.open(content,  { size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return `with: ${reason}`;
    }
  }

  statusBaseColorCode(status) {
    switch (status) {
      case 'u':
        return '#95d9f0';

      case 'h':
        return '#BDA5D7';

      case 'ou':
        return '#88ca92';

      case 'uu':
        return '#ef6262';

      case 'w':
        return '#ccc';

      case 'l':
        return '#BDA5D7';

      case 'd':
        return '#303030';

      case 'n':
        return '#ffdf6e';

      default:
        return '#ffdc7a';
    }
  }

  isDispute(status) {
    if (status === 'd') {
      return true;
    }
  }

  displayDisputeInfo(mid) {
    this.selection = false;
    const params = {'mid': mid, 'project_id': projectInfo.projectId};
    // this.spinner.show();
    this.appService.postSend('getdisputeDetails', params)
    .subscribe(
      restItems => {
        this.selection = true;
        const apiResponse = [];
        apiResponse.push({type: 'disputeData'});
        apiResponse.push(restItems);
        this.apiResponse = apiResponse;
      }
    );
  }

  doRefresh() {
    this.manpowerRefresh.emit();
    this.refresh = true;

    const params = {'page': 0, 'project_id': projectInfo.projectId, 'startDate': this.currentStartDate, 'endDate': this.currentEndDate};
    this.appService.postSend('getProjectManpower', params)
    .subscribe(
      restItems => {
        this.projectData = restItems;
        this.stopProcess = this.projectData.readonly;
        if(this.projectData.taskRights.can_update == false){
          this.stopProcess = true;
        }
      }
    );
  }

  loadProjectManpowerBetweenDates() {
    const params = {'page': 0, 'project_id': projectInfo.projectId, 'startDate': this.currentStartDate, 'endDate': this.currentEndDate};
    this.appService.postSend('getProjectManpower', params)
    .subscribe(
      restItems => {
        this.scrollCount = [];
        this.headerCol = [];
        this.projectData = restItems;
        this.stopProcess = this.projectData.readonly;
        if(this.projectData.taskRights.can_update == false){
          this.stopProcess = true;
        }
      }
    );
  }

  plotResource(onTask) {
    const element = document.getElementById('x');
    element.classList.add('rotate');
    element.classList.add('placed');
    if(this.stopProcess === true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }
    this.addData(this.plotterList, onTask);
  }

  /*********************** Drop from Repoort **************************/
  addData(source: any, target?: any) {

    const projectId = projectInfo.projectId;
    const params = {
      'project_id': projectId,
      'task_id': target.taskid,
      'page': this.currentPage,
      'employee_id': source.id
    };

    this.postRestItems('allocateManPowerToProject', params)
    .subscribe(
      restItems => {
        const apiResponse = [];
        apiResponse.push({type: 'allocateManPowerToProject'});
        apiResponse.push(restItems);
        this.apiResponse = apiResponse;
      }
    );
  }

  removeData(source: any): boolean {
    const itemIndex: number = this.items.indexOf(source);
    if (itemIndex >= 0) {
      this.items.splice(itemIndex, 1);
      return true;
    }
    return false;
  }

  accordionPlay(row, num) {
    for (let i = 0; i < row.length; i++) {
      if (row[i].classList.contains('expand')) {
        row[i].classList.remove('expand');
        row[i].classList.add('collapse');
        this.accordionClass[num] = 'collapse';
      } else {
        row[i].classList.remove('collapse');
        row[i].classList.add('expand');
        this.accordionClass[num] = 'expand';
      }
    }
  }

  employeeToggle(num) {
    const row = this.accordion.nativeElement.getElementsByClassName( 'mp_row' + num);
    this.accordionPlay(row, num);
  }

  clearDateRange() {
    this.isDateRangeSelected = false;
    this.doRefresh();
  }

  filterDateRange() {
    this.isDateRangeSelected = true;
    this.loadProjectManpowerBetweenDates();
  }

  getStories() {
   // this.spinner.show();
   const projectId = (window.location.pathname.split( '/' )[window.location.pathname.split( '/' ).length - 1 ]);

    if (projectId === '') {
      Swal('Oops...', 'URL went wrong!', 'error');
      return;
    }
    let params = <any>{'page': this.currentPage++, 'project_id': projectId};

    if(this.isDateRangeSelected == true){
      params.startDate = this.currentStartDate;
      params.endDate = this.currentEndDate;
    }
    if(this.isReachedAtEnd == false){
      return this.appService.postSend('getProjectManpower', params).do(this.processData);
    }
  }

  getRow(data) {
  }

  private processData = (data) => {
    let projectEndDate = new Date(data.info.edate);
    let currentDate = new Date();

    if(currentDate > projectEndDate){
        Swal('Oops...', "Project end date is crossed, Please ask manager to update", 'error');
    }

    if (data.message) {
      Swal('Oops...', data.message, 'error');
      // this.spinner.hide();
    } else {
      this.projectData = data;
      this.stopProcess = this.projectData.readonly;
      if(this.projectData.taskRights.can_update == false){
        this.stopProcess = true;
      }
      console.log(this.processData);
    }
  }

  /*****************EDITING HOURS IN GRID**********************/
  triggerFalseDBLClick(mid, displayToggle) {
    if (+this.nextElementMid === +mid) {
      displayToggle = false;
    }
    // const el: HTMLElement = this.accordion.nativeElement.getElementsByClassName('mid' + mid)[0] as HTMLElement;
    // const childElement: HTMLElement = el.getElementsByClassName('edit_hours_info')[0] as HTMLElement;
    var nextHours  = null;
    for (let index = 0; index < this.scrollCount[0].length; index++) {
      const element = this.scrollCount[0][index];

      for (let i = 0; i < element.records.length; i++) {
        const rec = element.records[i];
        const found = (rec.hours).find(x => (x.mid === +mid));
        if (found) {
          nextHours = found;
          if ((this.hoursStatus) &&
          (+this.nextElementMid === +mid ) && (
            this.hoursStatus.toLowerCase() === 'w' ||
            this.hoursStatus.toLowerCase() === 'h' ||
            this.hoursStatus.toLowerCase() === 'l')
          ) {
            let statusName = 'WeekOff'
            if (this.hoursStatus.toLowerCase() === 'l') {
              statusName = 'Leave'
            } else if (this.hoursStatus.toLowerCase() === 'h') {
              statusName = 'Holiday'
            }
            Swal({
              title: 'Are you sure?',
              text: `You are assigning to ${statusName}!`,
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, Edit it!'
            }).then((result) => {

              if (result.value) {
                (rec.hours).find(x => (x.mid === +mid)).display = displayToggle;
                if (!displayToggle && !this.mid.find(x => x === mid)) {
                  this.mid = [];
                  this.mid.push(mid)
                }
              } else {
                (rec.hours).find(x => (x.mid === +mid)).display = true;
              }
            });
          } else {
            (rec.hours).find(x => (x.mid === +mid)).display = displayToggle;
            if (!displayToggle && !this.mid.find(x => x === mid)) {
              this.mid = [];
              this.mid.push(mid)
            }
          }
          // childElement.getElementsByTagName('input')[0].focus();
        }
      }
    }
      const angularScope = this;
      setTimeout(function() {
      if(document.getElementById('edit_hours_' + mid)){
        angularScope.oldValue = nextHours.h;
        angularScope.oldStatus = nextHours.s;
        angularScope.newHours = nextHours.h;
        document.getElementById('edit_hours_' + mid).focus();
      }
    }, 100);
  }

  onSingleEditdblClick(hours) {

    if(this.stopProcess === true){
      return;
    }

    this.mode = CURRENT_MODE.SINGLE_EDITING_HOURS;
    if (this.mid.length) {
      this.triggerFalseDBLClick(this.mid[0], true)
      this.mid = [];
    }
    console.log("hours vdlid ");
    console.log(hours);
    this.mid.push(hours.mid);
    this.selection = false;
    hours.display = false;
    this.newHours = hours.h;
    this.oldValue = hours.h;
    this.oldStatus = hours.s;
    this.triggerFalseDBLClick(hours.mid, false)
  }

  onKey(event, hours, empId) {

    if (event.keyCode === KEY_CODE.TAB_PRESS) {
      var type  = "";
      if(this.oldStatus === 'h'){
        type  = "Holiday";
      }else if(this.oldStatus === 'l'){
        type  = "Leave";
      }else if(this.oldStatus === 'w'){
        type  = "Weekly Off";
      }

      if(type != ""){
        //take cofirmation
        Swal({
          title: 'Are you sure?',
          text: 'You are trying to plot employee on ' + type + '. Are you sure?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {

          if (result.value) {
            this.updateHours();
          }
        });
      }else{
        this.updateHours();
      }


      this.newHours = hours.h;
      this.oldValue = hours.h;
      this.oldStatus = hours.s;
      console.log(hours);
      this.nextTabElement(hours.mid);
    } else if (event.keyCode === KEY_CODE.ENTER_PRESS) {
      var type  = "";
      if(this.oldStatus === 'h'){
        type  = "Holiday";
      }else if(this.oldStatus === 'l'){
        type  = "Leave";
      }else if(this.oldStatus === 'w'){
        type  = "Weekly Off";
      }
      const angularScope = this;

      setTimeout(function(){
        if(type != "" && this.newHours != 0){
          //take cofirmation
          Swal({
            title: 'Are you sure?',
            text: 'You are trying to plot employee on ' + type + '. Are you sure?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.value) {
              angularScope.updateHours();
              angularScope.toggleTD(hours.mid);
            }
          });
        }else{
          angularScope.updateHours();
        }
      },500);

      hours.display = true;
      this.newHours = null;
      this.oldValue = null;
      this.oldStatus = null;
      this.mode = CURRENT_MODE.NONE;
      this.selection = true;
      this.nextElementMid = null;
    }
    console.log(hours);
  }

  nextTabElement(mid) {
    const lastElement = this.accordion.nativeElement.getElementsByClassName('mid' + mid)[0];
    console.log(lastElement.nextElementSibling);
    if (lastElement.nextElementSibling === null) {
      this.nextElementMid = [];
      this.mid = [];
      this.mode = CURRENT_MODE.NONE;
      Swal('Oops...', 'No Next Element Found!', 'error');
    } else {
      this.nextElementMid = (lastElement.nextElementSibling.className.split(' ')).find(x => (x.includes('mid'))).slice(3);
      const nextElementText = lastElement.nextElementSibling.innerText;
      this.hoursStatus = nextElementText.trim();
      if ((nextElementText.trim() === 'W') || (nextElementText.trim() === 'L') || (nextElementText.trim() === 'H')) {
        this.nextTabElement(this.nextElementMid);
      }else{
        this.triggerFalseDBLClick(this.nextElementMid, false);
      }
    }
  }

  /***************************SHIFTING*********************************/
  longPressForShifting($event, taskId, mid, itemId, status) {
    if (!this.isShifting) {
      this.moveHours($event, taskId, mid, itemId, status)
    }
  }

  /*************** SINGLE AND MULTIPLE HOURS UPDATE AND EDIT *****************/
  onSelection(event, taskid, colnum, empId, status) {

    if(this.stopProcess === true){
      return;
    }

    if(event.shiftKey) {
      console.log(this.mid);
      this.toggleTD(colnum);
      let indexToRemove = this.mid.indexOf(colnum.toString());
      if(indexToRemove != -1){
        console.log(indexToRemove);
        console.log(colnum);
        this.mid.splice(indexToRemove, 1);
        console.log(this.mid);
      }      
      return;
    }


    // On click of dispute info Return
    if (!this.selection) {
      return;
    }

    if (this.mid.length &&  this.selectedTaskRow !== taskid) {
      Swal('Oops...', 'Selection outside the task row is not allowed!', 'error');
      return;
    }
    // if (status === 'l' || status === 'w' || status === 'h') {
      // Swal('Oops...', 'Leave, Holiday and weekoff selections are not allowed!', 'error');
      // return;
    // }
    if (this.isShifting) {
      return;
    } else if (!this.mid.length || this.selectionStartFrom === null) {
      if (!this.mid.length) {
        this.selectedTaskRow = taskid;
        this.empId = empId;
        this.selectionStartFrom = colnum;
      }
      this.toggleTD(colnum);
      console.log(this.empId, this.selectedTaskRow, '================INSIDE OnSelection FUNCTION==============', this.isShifting)
    } else {
      this.toggleInBetweenTD(colnum);
    }
  }

  onRightClick(event, colnum) {
    if (this.mid.length === 0) {
      return true;
    } else if (this.isShifting) {
      this.shiftingHours(colnum);
    } else if (this.mode === CURRENT_MODE.SINGLE_EDITING_HOURS) {
      return false;
    } else {
      this.modalRef  = this.modalService.open(this.replaceHours,  { size: 'sm' });
    }
    return false;
  }

  toggleTD(num,after_update = null) {
    if (this.isShifting) {
      return;
    }
    const td = this.accordion.nativeElement.getElementsByClassName('mid' + num);

    if(td.length == 0){
      return;
    }
    if (td[0].classList.contains('selected')) {
      td[0].classList.remove('selected');
      this.mid.splice(this.mid.indexOf(num), 1);
    } else {
      if(after_update != null){
        return;
      }
      if (
        this.getElementDetail(num).hours.toLowerCase() === 'l' ||
        this.getElementDetail(num).hours.toLowerCase() === 'w' ||
        this.getElementDetail(num).hours.toLowerCase() === 'h'
      ) {
        this.mid.push(0); // 0 indication for skipping
        return;
      }
      td[0].classList.add('selected');
      const findmid = num + 1;

      if (this.mid.indexOf(findmid) === 0) {
        this.mid.splice(this.mid.indexOf(findmid), 0, num);
      } else {
        this.mid.push(num);
      }
    }
  }

  toggleInBetweenTD(num) {
    const tds = this.accordion.nativeElement.getElementsByClassName('mid' + num);
    console.log(tds[0].nextElementSibling)
    this.toggleTD(this.selectionStartFrom);
    let selectionEndTo = num;
    if (num < this.selectionStartFrom) {
      if (!this.mid) {
        // this.selectionForword = false;
      }
      selectionEndTo = this.selectionStartFrom;
      this.selectionStartFrom = num;
    }
    //We take start element and run loop to select all element in between it.
    
    let startElement = this.accordion.nativeElement.getElementsByClassName('mid' + this.selectionStartFrom)[0].previousElementSibling;
    //user has clicked on first element of row hence there is no previous sibling.
    if(startElement === null){
      startElement = this.accordion.nativeElement.getElementsByClassName('mid' + this.selectionStartFrom)[0];
      let indexOfStart = (startElement.className.split(' ')).find(x => (x.includes('mid'))).slice(3);
      this.toggleTD(indexOfStart);
    }

    let endElement = this.accordion.nativeElement.getElementsByClassName('mid' + selectionEndTo)[0]

    let currentElement = startElement;
    let currentIndex = (startElement.className.split(' ')).find(x => (x.includes('mid'))).slice(3);
    let endIndex = (endElement.className.split(' ')).find(x => (x.includes('mid'))).slice(3);

    while(currentIndex <= endIndex){
      this.toggleTD(currentIndex);
      if(currentElement === null){
        break;
      }
      currentIndex = (currentElement.className.split(' ')).find(x => (x.includes('mid'))).slice(3);
      currentElement = currentElement.nextElementSibling;
    }
    //HACK
    if(currentElement !== null){
    currentIndex = (currentElement.previousElementSibling.previousElementSibling.className.split(' ')).find(x => (x.includes('mid'))).slice(3);
    this.selectionStartFrom = currentIndex;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log(event.keyCode);
    if (event.keyCode === KEY_CODE.ESC_PRESS) {
      this.clearAll();
      const element = document.getElementById('x');
      element.classList.add('rotate');
      element.classList.add('placed');

      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Data Cleared!',
        showConfirmButton: false,
        timer: 1500
      });
    }
  }

  clearAll() {
    const selectedTDS = this.accordion.nativeElement.getElementsByClassName('selected');

    const counter = selectedTDS.length - 1 ;
    for (let i = counter; i >= 0; i--) {
      selectedTDS[i].classList.remove('selected');
    }

    this.empId = null;
    this.mid = [];
    this.values = [];
    this.selectedTaskRow = null;
    this.selectionStartFrom = null;
    this.isShifting = false;
    const element = document.getElementById('shift-hours');
    element.classList.add('rotate');
    element.style.display = 'none';
    element.classList.remove('placed');
  }

  updateHoursResponse(params, typeApi){
    this.selectionStartFrom = null;
    this.mid = [];
    this.newHours = null;
    this.postRestItems('updateProjectManpower', params)
      .subscribe(
        restItems => {
          const apiResponse = [];
          apiResponse.push({type: typeApi});
          apiResponse.push(restItems);
          this.apiResponse = apiResponse;
          if(apiResponse[1].data.length > 0){
            for(let j = 0 ;j<apiResponse[1].data.length ;j++){
              let mid = apiResponse[1].data[j].mid;
              for (let index = 0; index < this.scrollCount[0].length; index++) {
                const element = this.scrollCount[0][index];
                for (let i = 0; i < element.records.length; i++) {
                  const rec = element.records[i];
                  if(element.id == apiResponse[1].availabiityUpdate[j].employeeId){
                    element.total_hours = apiResponse[1].availabiityUpdate[j].total_hours;
                  }
                  let found = (rec.hours).find(x => (x.mid === +mid));
                  if (found) {
                    console.log(apiResponse);
                    found.h = apiResponse[1].data[j].updated_value;
                    found.s = apiResponse[1].data[j].status;
                    this.toggleTD(mid,'after_update');
                  }
                  let updatedDate = apiResponse[1].data[j].date;
                  let employeeId = apiResponse[1].availabiityUpdate[j].employeeId;
                  let allocatedHours = apiResponse[1].availabiityUpdate[j].allocatedHours;
                  let status = apiResponse[1].availabiityUpdate[j].status;
                  //Now updating sumamry rows.
                  found = (element.summary.hours.find(x => (x.date === updatedDate && x.empId === employeeId)))
                  if(found){
                    found.h = allocatedHours;
                    found.s = status;
                  }
                }
              }
            }
          }
          this.manpowerRefresh.emit();
          this.getDismissReason('save');
          if(this.modalRef){
            this.modalRef.close();
          }
        }
      );
  }

  updateHours(): boolean {
    const selectedMid = [];
    (this.mid).forEach(element => {
      if (element) {
        selectedMid.push(element);
      }
    });

    if(this.mid.length == 1){
      const td = this.accordion.nativeElement.getElementsByClassName('mid' + this.mid[0]);
      if (td[0].classList.contains('selected')) {
        td[0].classList.remove('selected');
        this.mid.splice(this.mid.indexOf(this.mid[0]), 1);
      }
    }
    if (+(this.newHours) > 24) {

      this.newHours = null;
      Swal('Oops...', 'Updated Hours are greater than 24hours!', 'error');
      return false;

    } else if (+(this.newHours) < 0) {

      this.newHours = null;
      Swal('Oops...', 'Negative Hours are not allowed!', 'error');
      return false;

    } else if (+(this.newHours) === 0) {
      //In case earier there was zero and now it is zero then don't do anything.
      if(this.oldValue == this.newHours){
        (this.mid).forEach(element => {
          if (element) {
            this.toggleTD(element,'after_update');
          }
        });
        return;
      }
      Swal({
        title: 'Are you sure?',
        text: 'You are assigning Zero hours!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {

        if (result.value) {
          const midValues = [];
          (selectedMid).forEach(element => {
            midValues.push(0);
          });
          const params = { 'mid': selectedMid, 'values': midValues ,'projectId': projectInfo.projectId};

          this.updateHoursResponse(params,'deleteHours');

        }
      });

    } else if (typeof(+(this.newHours)) === 'string') {

    } else {
      //There is no change hence do not call any value
      if(this.oldValue == this.newHours){
        return;
      }

      const midValues = [];
      (selectedMid).forEach(element => {
        midValues.push(+(this.newHours));
      });
      const params = {'mid': selectedMid, 'values': midValues, 'sid': [] , 'projectId': projectInfo.projectId};
      this.updateHoursResponse(params,'updateHours');

    }
  }

  getElementDetail(mid) {
    const element = this.accordion.nativeElement.getElementsByClassName('mid' + mid)[0];
    return {'id': mid, 'hours': element.innerText, 'hours_state': ''}
  }

  /************************replace Hours********************************/
  moveHours(event, taskid, colnum, empId, status) {

    if (status === 'l') {
      Swal('Oops...', 'Leave selection not allowed!', 'error');
      return;
    }

    if (this.isShifting === false) { // i.e first click
      if(this.mid.length == 0){
        this.toggleTD(colnum);
      }
      this.isShifting = true;

      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Shifting hours mode on',
        showConfirmButton: false,
        timer: 1500
      });

      const IE = document.all;
      let tempX = 0;
      let tempY = 0;
      const element = document.getElementById('shift-hours');
      element.style.display = 'none';
      element.classList.remove('rotate');
      element.classList.remove('placed');
      document.onmousemove = function getMouseXY(abc) {
        if (element.classList.contains('placed')) {
          return;
        }
        tempX = abc.pageX;
        tempY = abc.pageY;
        element.style.position = 'absolute';
        element.style.display = 'block';
        element.style.left = tempX - 50 + 'px';
        element.style.top = tempY - 50 + 'px';
      }

    } else if (this.mid.length > 0 && this.isShifting === true) { // i.e second click
      return;
    }

  }

  shiftingHours(colnum) {
    // Todo Include first colnum
    // if first element is l W H => ?
    if ( this.mid[0] === 0) {
      this.mid.shift();
    }
    const sid = [colnum];
    let isLeaveExist = false;

    for (let index = 1; index < this.mid.length; index++) {

      const nextElement = this.accordion.nativeElement.getElementsByClassName('mid' + colnum)[0];

      colnum = (nextElement.nextElementSibling.className.split(' ')).find(x => (x.includes('mid'))).slice(3);
      const colnumDetail = this.getElementDetail(colnum);
      if ((colnumDetail.hours === 'l' || colnumDetail.hours === 'L') || (colnumDetail.hours === 'w' || colnumDetail.hours === 'W') || (colnumDetail.hours === 'h' || colnumDetail.hours === 'H')) {
        //isLeaveExist = true;
        index = index - 1;
      }
      if (!this.mid[index]) {
        continue;
      }
      sid.push(+colnum);
    }

    const midValues = [];
    this.values = [];
    (this.mid).forEach(element => {
      if (element) {
        midValues.push(element);
        this.values.push(this.getElementDetail(element).hours);
      }
    });

    if (isLeaveExist) {
      Swal({
        title: 'Are you sure?',
        text: 'You want to cancel Approval Leave!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {

        if (result.value) {
          this.shiftingHoursRequest({'employeeId': this.empId, 'mid': midValues, 'values': this.values, 'sid': sid });
        }
      });
    } else {
      this.shiftingHoursRequest({'employeeId': this.empId, 'mid': midValues, 'values': this.values, 'sid': sid });
    }
  }

  shiftingHoursRequest(params) {
    this.postRestItems('updateProjectManpower', params)
    .subscribe(
      restItems => {
        const element = document.getElementById('shift-hours');
        element.classList.add('rotate');
        element.classList.add('placed');
        const apiResponse = [];
        apiResponse.push({type: 'updateHours'});
        apiResponse.push(restItems);
        this.apiResponse = apiResponse;
        this.getDismissReason('save');
        this.clearAll();
        this.doRefresh();
      }
    );
  }
  /************************remove Plotter********************************/

  removePlotter(rowNum, taskId, empId) {

    if(this.stopProcess === true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }

    Swal({
      title: 'Are you sure?',
      text: 'you want to remove employee from task?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.value) {
        const params = { 'project_id': projectInfo.projectId, 'task_id': taskId, 'page': this.currentPage, 'employee_id': empId };
        this.postRestItems('removeEmployeeFromTask', params)
        .subscribe(
          restItems => {
            const apiResponse = [];
            apiResponse.push({type: 'deleteTask'});
            apiResponse.push(restItems);
            this.apiResponse = apiResponse;
          }
        );
      }
    });
  }
}
