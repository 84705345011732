import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'angular-calendar';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { CdkTableModule } from '@angular/cdk/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';

// ----------------------------------------------


import { TimesheetComponent, DialogComponent } from './timesheet/timesheet.component';
import { WeekCalendarModule } from './timesheet/week-calendar/week-calendar.module';
import { SheetComponent, NotesDialogComponent } from './timesheet/sheet/sheet.component';
// import { AppService } from './app.service';
// import { AppApiInterceptor } from './app-api.interceptor';
// ----------------------------------------------

import { AppComponent } from './app.component';
import { ManpowerComponent } from './manpower/manpower.component';
import { ManpowerReportComponent } from './manpower/manpower-report/manpower-report.component';
import { ManpowerPlottingComponent } from './manpower/manpower-plotting/manpower-plotting.component';
import { ManpowerDialogModule } from './manpower/manpower-dialog/manpower-dialog.module';
import { InfiniteScrollerDirective } from './infinite-scroller.directive';
import { AppService } from './app.service';
import { AppApiInterceptor } from './app-api.interceptor';
import { LongPressDirective } from './longpress.directive';
import { AutofocusDirective } from './autofocus.directive';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
  exports: [
    MatButtonModule,
    CdkTableModule,
  ],
  declarations: [
    LongPressDirective,
    AutofocusDirective,
    AppComponent,
    TimesheetComponent,
    DialogComponent,
    SheetComponent,
    NotesDialogComponent,
    ManpowerComponent,
    ManpowerReportComponent,
    ManpowerPlottingComponent,
    InfiniteScrollerDirective
  ],
  imports: [
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgHttpLoaderModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    WeekCalendarModule,
    ManpowerDialogModule,
    AppRoutingModule,
    NgbModule.forRoot()
  ],
  providers: [
    AppService,
    { provide: HTTP_INTERCEPTORS, useClass: AppApiInterceptor, multi: true }
  ],
  entryComponents: [AppComponent, DialogComponent, NotesDialogComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
platformBrowserDynamic().bootstrapModule(AppModule);
