import {
  Component,
  Input,
  Output,
  EventEmitter,
  DoCheck,
  KeyValueDiffers
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes
  } from '@angular/animations';
import { BrowserModule } from '@angular/platform-browser';
import * as projectInfo from '../../globals';
import { AppService } from '../../app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-custom-block',
  template: `
    <style>
      .list-block
      {
        float: left;
        background: #f2f2f2;
        width: 100%;
        max-height: 165px;
        overflow-y: scroll;
        min-height: 130px;
        border: 1px solid #ccc;
      }
      .list-block table{
        border-radius: 15px;
      }
      .list-block > ul
      {
        margin: 0;
        padding: 0;
      }
      .list-block li
      {
        border-bottom: thin solid #f5f5f5;
        padding: 5px;
        list-style-type: none;
      }
      .list_block_cancel_btn {
        color: #FE5E62;
        font-weight: bold;
        border-color: #FE5E62;
        border-radius: 25px;
        padding: 2px 7px 2px 7px !important;
      }
      .list_block_cancel_btn:hover {
        background-color: #FE5E16;
        color: #fff;
        font-weight: bold;
        border-radius: 25px;
        transition: 1s;
      }
      .list-block table td  {
        font-weight: normal !important;
        vertical-align: middle !important;
        font-size: 13px !important;
        padding: 10px !important;
        height: auto !important;
        max-width: 100px;
        min-width: auto !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .remove_emp_task_btn {
        position: absolute;
        right: 30px;
        margin-top: -15px;
      }
    </style>
    <h5><b>Task Allocation:</b></h5>
    <div class="list-block">
    <table class="table table-bordered text-center">
      <tr *ngFor="let item of items"
      [@flyInOut]='item.state'>
        <td>{{item.name}}</td>
        <td>
          <input
          class="form-control"
          type="number"
          min="0"
          [readonly]="item.state === 'old'"
          maxlength="3"
          size="2"
          max="{{item.maxHours}}"
          value="{{item.hours}}"
          (focusout)="assignHours(item, $event.target.value)">
        </td>
        <td><a *ngIf="item.state === 'new'" class='btn list_block_cancel_btn' (click)="removeItem(item.id, item.hours)">&times;</a></td>
      </tr>
    </table>
    </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('in', style({opacity: 1, transform: 'translateX(0)'})),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateX(-100%)'
        }),
        animate('0.2s ease-in')
      ]),
      transition('* => void', [
        animate('0.2s 0.1s ease-out', style({
          opacity: 0,
          transform: 'translateX(100%)'
        }))
      ])
    ])
  ]
})
export class CustomBlockComponent {
  @Input() getItem: Array<any> = [];
  @Input() defaultItem: Array<any> = [];
  @Input() mode: any;
  acceptItem: boolean;
  @Input() empListForTaskAllocation: Array<any> = [];
  differ: any;
  // public itemPadding: number = 30;
  // public targetItem: any = null;
  items: Array<any> = [];
  restItems: {};
  @Output() assignItem: EventEmitter < any > = new EventEmitter();
  @Output() deleteItem: EventEmitter < any > = new EventEmitter();

  constructor(
    private differs: KeyValueDiffers,
    private appService: AppService,
  ) {
    this.differ = this.differs.find({}).create();
    this.differ['getItem'] = differs.find(this.getItem).create();
    this.differ['defaultItem'] = differs.find(this.defaultItem).create();
    this.acceptItem = true;
  }

  ngDoCheck() {


    // check for object mutation
    const change = this.differ.diff(this);
    console.log(this.items);
    if (change) {
      change.forEachChangedItem(item => {
        if (item.key === 'getItem') {

        //  this.items = this.items.concat(this.getItem);
        } else if (item.key === 'defaultItem') {
        } else if (item.key === 'acceptItem') {
          // this.items = this.items.concat(this.getItem);
         }
      });
    }

    var itemChanges = this.differ['getItem'].diff(this.getItem);
    console.log('itemChanges');
    console.log(itemChanges);
    var taskDetailsChanges = this.differ['defaultItem'].diff(this.defaultItem);
    console.log(taskDetailsChanges);

    if(taskDetailsChanges){
      taskDetailsChanges.forEachChangedItem(item => {
        if(item.key == 'date'){
          //Since start date is changed we again have to check max no of hours for each existing added users.
          (this.items).forEach(element => {
              let params = {
                project_id : projectInfo.projectId,
                due_date: this.defaultItem['date'],
                assignee: [element.id],
                estimated_time: [0]
              };
              this.appService.postSend('checkForHoursAllocationInProject', params)
                .subscribe(
                  restItems => {
                    if(restItems['success'] === true){
                      const found = (this.items).find(x => (x.id === restItems['id']));
                      if(found){
                        found['maxHours'] = restItems['totalPossibleHoursForEmployee'];
                        if(found['hours'] > found['maxHours']){
                          Swal(
                            'Error',
                            'Can not assign hours ' + found['hours'] + ' to ' + found['name'] + ". Maximum possible hours are "+found['maxHours'],
                            'error'
                          );
                          found['hours'] = 0;
                          this.assignItem.emit({
                            'id': found['id'],
                            'hours': found['hours']
                          });
                        }
                      }
                    }
                  }
              );
          });
        }
      });
    }


    if (itemChanges) {
        if (!(this.items.find(x => x.id === this.getItem['id'])) && this.acceptItem) {
          if(this.getItem.length >= 1){
            if (this.getItem && this.getItem.length >= 1) {
              for (const entry of this.getItem) {
                if (!(this.items.find(x => x.id === entry.id))) {
                    entry['state'] = 'old';
                    this.items.push(entry);
                    //calling service to get max possible hours for each employee.
                    let params = {
                      project_id : projectInfo.projectId,
                      due_date: this.defaultItem['date'],
                      assignee: [entry.id],
                      estimated_time: [0]
                    };
                    this.appService.postSend('checkForHoursAllocationInProject', params)
                      .subscribe(
                        restItems => {
                          if(restItems['success'] === true){
                            const found = (this.items).find(x => (x.id === restItems['id']));
                            if(found){
                              found['maxHours'] = restItems['totalPossibleHoursForEmployee'];
                            }
                          }
                        }
                    );
                }
              }
            }
          }else{
            this.getItem['hours'] = 0;
            this.getItem['state'] = 'in';
            this.getItem['state'] = 'new';
            console.log('is i8s changhe ');
            console.log(this.deleteItem);
            this.items.push(this.getItem);
          }
          //calling service to get max possible hours for each employee.
            let params = {
              project_id : projectInfo.projectId,
              due_date: this.defaultItem['date'],
              assignee: [this.getItem['id']],
              estimated_time: [0]
            };
            this.appService.postSend('checkForHoursAllocationInProject', params)
              .subscribe(
                restItems => {
                  if(restItems['success'] === true){
                    const found = (this.items).find(x => (x.id === restItems['id']));
                    if(found){
                      found['maxHours'] = restItems['totalPossibleHoursForEmployee'];
                    }
                  }
                }
            );
        }else{
          this.acceptItem = true;
        }
    }

  }

  assignHours(item, hours) {
    //Disabled it as lots of events are happening at same time.
    //Instead of API call check for no of hours we are passing here.
    (this.items).forEach(element => {
      if (element.id === item.id) {
        if(element.maxHours < hours){
            Swal(
              'Error',
              'Can not assign hours ' + hours + ' to ' + item.name + ". Maximum possible hours are "+element.maxHours,
              'error'
            )
        }else{
          element.hours = Number(hours);
          this.assignItem.emit({
            'id': item.id,
            'hours': hours
          });
        }
      }
    });
  }

  removeItem(id, hours) {
    //TODO User has to click two times to remove allocations, update it for single time.
    // this.items.find
    const toDelete = new Set([id]);
    this.items = this.items.filter(obj => !toDelete.has(obj.id));
    this.acceptItem = false;
    // if (hours === 0) {
      this.deleteItem.emit(id);
    // }
  }
}
