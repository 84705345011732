import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  DoCheck,
  KeyValueDiffers,
  ViewChild,
  ElementRef,
  Inject
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  query,
  stagger,
  transition
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppService } from '../../app.service';
import * as setting from '../../globals';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-notes-dialog',
  template:
  `
  <label>Notes:</label>
  <img src="../assets/imgs/pin.png" class="pin_img" style="  position: absolute;  margin-top: -37px; margin-left: 155px;">
  <textarea matInput [(ngModel)]="data.notes" style="background-color: #F5C4C6;border: none;"></textarea>
   <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" class="btn btn-danger" style="margin-right: 10px; border-radius: 25px;">No Thanks</button>
    <button mat-button [mat-dialog-close]="data.notes" class="btn btn-info" cdkFocusInitial style="border-radius: 25px;">Ok</button>
   </div>
   `,
})
export class NotesDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

// ToDO : add calendar in timesheet
@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate(500)
      ]),
      transition('* => void', [
        animate(500, style({transform: 'translateX(100%)'}))
      ])
    ])
  ],
  providers: [DatePipe]
})
export class SheetComponent implements OnInit, AfterViewInit {

  data: any = [];
  // showEditTable: boolean; +(timesheetSetting.timesheetWithoutPrevWeek)
  editRowID: number;
  differ: any;
  restItems: any;
  projectList: Array<any> = [{project_name: 'Loading..', project_id: ''}];
  tasksList: Array<any>;
  timesheetForAWeekData: any = [];
  // dateWiseSum: string;
  projectId: number;
  taskId: number;
  timesheetId: string;
  submit: boolean;
  deleteDraftTimesheetData: any;
  selectProject: string;
  selectTask: string;
  isEditable: boolean;
  // absentDateValidator: any = [];
  // halfDayLeaveValidator: any = [];
  // leavesDateValidator: any = [];
  // weekOfDays: any = [];
  // holidayDatesValidator: any = []; // Coming soon
  response: any = [];

  @Input() startDate: string;
  @Input() endDate: string;

  // @ViewChild('timesheet', {read: ElementRef}) timesheet: ElementRef;
  @ViewChild('timesheet') timesheet: ElementRef<HTMLInputElement>;
  constructor(
    private el: ElementRef,
    public dialog: MatDialog,
    private appService: AppService,
    public  datepipe: DatePipe,
    private differs: KeyValueDiffers
  ) {
    this.differ = this.differs.find({}).create();
    this.editRowID = 0;
    this.submit = true;
    this.selectProject = 'Loading ... ';
    this.selectTask = 'Loading ... ';
    this.isEditable  = false;
  }

  ngOnInit() {
    // console.log('editRowID == ', this.editRowID);
    this.getRestItems('admin/getProjects', '')
      .subscribe(
        restItems => {
          this.restItems = restItems;
          this.projectList = this.restItems.projects;
          this.selectProject = '--- Select Project ---';
          // console.log(restItems);
        }
      );
    this.callGetTimesheetForAWeekServices();
  }

  ngAfterViewInit() {
    const aDaysTotalHours = this.timesheet.nativeElement.getElementsByClassName('day-total');
    console.log('Get rows of day-total classname ============== ', aDaysTotalHours.length);

    for ( let i = 0; i < aDaysTotalHours.length; i++) {
      console.log(aDaysTotalHours[i]);
    }
    // console.log(this.timesheet.nativeElement.textContent);
    // console.log(this.timesheet.nativeElement.querySelector('.cal-weekend'));
    // console.log(this.timesheet.nativeElement.innerHTML);
    // console.log(this.timesheet.nativeElement.querySelector('mwl-calendar-month-view'));
  }

  callGetTimesheetForAWeekServices() {

    this.data = [{ status: 'Loading ... ' }]
    const params = new HttpParams()
    .set('startdate', this.startDate)
    .set('enddate', this.endDate);
    this.getRestItems('admin/getdataoflastweekpending', params) // getTimesheetForAWeek
    .subscribe(
      timesheetForAWeekRestItems => {
        this.timesheetForAWeekData = timesheetForAWeekRestItems;
        // console.log('getTimesheetForAWeek', this.timesheetForAWeekData);
      }
    );
  }

  projectChanged(event, indexOfData) {
    this.data[indexOfData].project_id = event.target.value;
    // console.log(event.target.value, 'changggggggggggggggged', this.data);// this.itemArray.items[index] = newItem;

    this.projectId = event.target.value;
    const params = new HttpParams()
    .set('projects_id', event.target.value);
    this.getRestItems('admin/gettasks', params) // getTasksOfProject
    .subscribe(
      restItems => {
        this.restItems = restItems;
        this.tasksList = this.restItems.data;
        this.selectTask = '--- Select Task ---';
        // console.log(this.tasksList, 'tasksList', this.data);
      }
    );
  }

  taskChanged(event, indexOfData) {
    this.data[indexOfData].task_id = event.target.value;
    this.taskId = event.target.value;
    // console.log(event.target.value, 'changggggggggggggggged task', this.data);
  }

  // Read all REST Items
  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  postRestItems(url, params) {
    return this.appService.postSend(url, params);
  }

  ngDoCheck() {
    const change = this.differ.diff(this);
    if (change) {
      change.forEachChangedItem(item => {
        // console.log('item changed', item.key);
        if (item.key === 'endDate') {
          this.callGetTimesheetForAWeekServices();
        } else if (item.key === 'timesheetForAWeekData') {
          this.isEditable = false;
          this.timesheetId = this.timesheetForAWeekData.currentTimeSheetId;

          if (this.timesheetForAWeekData.lastWeekStatusRemark) {
            Swal(this.timesheetForAWeekData.lastWeekStatusRemark);
          }
          if (
            this.timesheetForAWeekData.status === 'Draft' ||
            this.timesheetForAWeekData.status === 'Rejected' ||
            (+(setting.timesheetWithoutPrevWeek)) ||
            this.timesheetForAWeekData.status === 'NA') {
            this.isEditable = true;
          }
          if (this.timesheetForAWeekData.status === 'Draft') {

            this.callCreateTimeSheetForAWeekOfDraft();
          } else if (this.timesheetForAWeekData.status === 'NA') {

            this.callCreateTimeSheetForAWeekOfNA();
          } else {
            this.callCreateTimeSheetForAWeek();
          }

          this.callApplyValidationOnTimeSheet();
        } else if (item.key === 'data') {
          console.log(':::::::::::::::::::  ', this.data, '  :::::::::::::::::::::')
        }
      });
    }
  }

  getTime(timeString) {
    // const h = '12:23:00';
    // const re = /\:/gi;
    //   const result = h.replace(re, '');

    const timeArray = timeString.split(':');


//     const datetime = new Date(today + 'T' + timeString + 'Z');
    console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
    console.log(timeArray)
//     console.log(typeof(datetime))
    console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')


      // total = total + (+result);
  }

  getDatesArray() {

    const dateArray = new Array();
    let fromDate = new Date(this.startDate + 'T00:00:00');
    const toDate   = new Date(this.endDate + 'T00:00:00');

    while (fromDate <= toDate) {
      dateArray.push(this.datepipe.transform(fromDate, 'yyyy-MM-dd'));
      fromDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
    }

    return dateArray;
  }

  isCurrentWeek() {
    const today = this.datepipe.transform(new Date(Date.now()), 'yyyy-MM-dd');

    const isTodayFound = this.getDatesArray().find(x => x === today);
    if (isTodayFound) {
      return true;
    }
    return false;
  }

  callCreateTimeSheetForAWeek() {

    const getdata = [];
    // console.log('timesheet week ::::::SERVER:::::: ', this.timesheetForAWeekData.data[0]);
    let total = 0;
    // let stringTotal = '00:00';

    for (let i = 0; i < this.timesheetForAWeekData.data.length; i++) {
      const x = i;
      const last = x - 1;
      if (i !== 0 && this.timesheetForAWeekData.data[i][0].timesheets_date !== this.timesheetForAWeekData.data[last][0].timesheets_date) {
        const str = String(total);
        getdata.push({ type: 'hoursCount', hours: str.substring(0, str.length - 2) + ':' + str.substring(str.length - 2), state: 'in' });
        total = 0;
      }
      const h = this.timesheetForAWeekData.data[i][0].hours;
      const re = /\:/gi;
      const result = h.replace(re, '');

      total = total + (+result);
      this.timesheetForAWeekData.data[i][0].state =  'in';
      getdata.push(this.timesheetForAWeekData.data[i][0]);
      if ( i === this.timesheetForAWeekData.data.length - 1) {
        const str = String(total);
        getdata.push({ type: 'hoursCount', hours: str.substring(0, str.length - 2) + ':' + str.substring(str.length - 2), state: 'in' });
        total = 0;
      }
    }
    this.data = getdata;
    console.log('timesheet week ::::::GENERATE:::::: callCreateTimeSheetForAWeek ', getdata);
  }

  callCreateTimeSheetForAWeekOfDraft() {

    const getdata = [];
    const days = this.getDatesArray();
    // console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', days, days.length)
    for (let i = 0; i < days.length; i++) {
      const dataObj = this.timesheetForAWeekData.data.filter(x => x[0].timesheets_date === days[i]);
      // console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$', days[i], dataObj);
      if (dataObj === '' || dataObj === undefined || dataObj.length === 0) {
        getdata.push({
          hours: '',
          id: i,
          notes: '',
          project_id: '',
          project_name: '',
          task_id: '',
          task_name: '',
          timesheets_date:  days[i],
          timesheets_id: '',
          timesheetsdates_id: '',
          state: 'in'
        });
        getdata.push({ type: 'hoursCount', hours: '00:00', state: 'in' });
      } else {
        let total = 0;
        // let stringTotal = '00:00';
        for (let x = 0; x < dataObj.length; x++) {
          dataObj[x][0].state = 'in';
          getdata.push(dataObj[x][0]);
          const h = dataObj[x][0].hours;
          const re = /\:/gi;
          const result = h.replace(re, '');

          total = total + (+result);
        }
        // console.log(total);
        const str = String(total);
        getdata.push({ type: 'hoursCount', hours: str.substring(0, str.length - 2) + ':' + str.substring(str.length - 2), state: 'in' });
      }
    }
    // console.log(getdata, '**************S');
    this.data = getdata;
    console.log('timesheet week ::::::GENERATE:::::: callCreateTimeSheetForAWeekOfDraft ', getdata);
  }

  callCreateTimeSheetForAWeekOfNA() {
    const getdata = [];

    const dateString = this.startDate + 'T00:00:00' ;
    const toDate = new Date(dateString);
    let increaseBY = 0;
    // default current week show
    // ToDO if no data found in multiple weekWeek : in new project
    for (let i = 1; i <= 7; i++) {

      if (i !== 1) {
        increaseBY = 1
      }
      const nextdate = new Date(toDate.setDate(toDate.getDate() + increaseBY));
      getdata.push({
        hours: '',
        id: i,
        notes: '',
        project_id: '',
        project_name: '',
        task_id: '',
        task_name: '',
        timesheets_date:  this.datepipe.transform(nextdate, 'yyyy-M-dd'),
        timesheets_id: '',
        timesheetsdates_id: '',
        state: 'in'
      });
      getdata.push({ type: 'hoursCount', hours: '00:00', state: 'in' });
    }
    this.data = getdata;
    console.log('timesheet week ::::::GENERATE:::::: callCreateTimeSheetForAWeekOfNA', getdata);
    // this.data = getdata;
  }

  callApplyValidationOnTimeSheet() {
    for (let i = 0; i < this.data.length; i++) {
      // Absent Date
      for (let x = 0; x < this.timesheetForAWeekData.absentDates.length; x++) {
        if (this.data[i].timesheets_date === this.timesheetForAWeekData.absentDates[x]) {
          this.data[i]['absent'] = 'true';
        }
      }

      // Half Day Leave
      for (let x = 0; x < this.timesheetForAWeekData.halfDayLeaves.length; x++) {
        if (this.data[i].timesheets_date === this.timesheetForAWeekData.halfDayLeaves[x]) {
          this.data[i]['halfDay'] = 'true';
        }
      }

      // Leaves Day
      for (let x = 0; x < this.timesheetForAWeekData.leavesdates.length; x++) {
        if (this.data[i].timesheets_date === this.timesheetForAWeekData.leavesdates[x]) {
          this.data[i]['leave'] = 'true';
        }
      }

      // Week Of Days
      for (let x = 0; x < this.timesheetForAWeekData.weekOffDays.length; x++) {
        if (this.data[i].timesheets_date === this.timesheetForAWeekData.weekOffDays[x]) {
          this.data[i]['weekOff'] = 'true';
        }
      }
    }
    // console.log('data after validation', this.data);
  }

  validationOnSubmitforApproval() {
    // 1. Key: min_full_day_hours (You need to put mandatory validation in front end if user as present for full day).
    // 2. Key: min_half_day_hours (You need to put mandatory validation in front end if user as present for half day).
    let h = 0;
    let min = 0;
    this.data.forEach(element => {
      console.log(element)
      // element.hours = '10:23';
      const ishalfDay = element.halfDay;
      const timeArray = element.hours.split(':');
      if (element.type) {
        // console.log(element)
        // this.getTime(element.hours)
        const realmin = min % 60
        const hours = Math.floor(min / 60)
        const totalHours = +h + (+hours)
        const totalHoursString = totalHours + ':' + realmin
        console.log('************************', h)
        console.log(hours)
        console.log(realmin)
        console.log('************************', totalHoursString)
        h = 0;
        min = 0;
      } else {
        h = h + timeArray[0];
        min = min + timeArray[1];
      }
    });
  }

  edit(row) {
    // If allow_to_fill === 1 THEN allow user to fill timesheet on weekend and as well as approved leaves
    if (
      (row.leave || row.weekOff) &&
      (+(setting.allowToFill))
    ) {
      this.editRowID = row.id;
    } else if (row.leave || row.absent || row.weekOff) {
      Swal( ' Edition not Allowed !!');
    } else if (this.isEditable) {
      this.editRowID = row.id;
    } else {
      Swal( this.timesheetForAWeekData.status  + ' for Approval !!');
      // console.log(this.editRowID, 'kkkkkkkkkkkkk editRowID');
    }
  }

  oneNotesDialog(value): void {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      width: '250px',
      data: { notes: value.notes }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      // this.animal = result;
      value.notes = result;
    });
  }

  updateDayHours(row) {
    console.log('###################   UPDATEDAYHOURS   ######################')
    console.log(row);

  }

  /*
  *
  * dates: YYYY-m-d format
  * projects : id of selected project
  * tasks: id of selected task
  * hours : hh:mm format
  * notes: optional
  * timesheetlastinsertid: Timesheet if of a week
  * timesheetdetailsId : timesheet details id

  * Here for the first time you can pass both timesheetdetailsId and timesheetlastinsertid as null
  * So it will generate record on server and send you back new detail id in timesheetId key
  * and unique id of timesheet you will get in inputforlastid param
  * You have to save this and pass it in other requests.
  * Also next time if I am updating any existing record already saved in draft mode
  * you have to pass timesheetdetailsId so it will update the record on server.
  */
 focusOutSaveToDraft(row) {
  // console.log('lllllllllllllllll', row);
  // TODO: Update Total hours

  if ( row.project_id === '') {
    row.project_id = this.projectId;
  }
  if ( row.task_id === '') {
    row.task_id = this.taskId;
  }

  const data = {
  'dates' : row.timesheets_date,
  'projects': row.project_id,
  'tasks': row.task_id,
  'hours': row.hours,
  'notes': row.notes,
  'timesheetlastinsertid': '',
  'timesheetdetailsId': ''};

  this.getRestItems('admin/saveasdraftfordynamicvalue', data) // saveTimeSheetAsDraft
  .subscribe(
    result => {
      this.response = result;
      this.timesheetId = this.response.timesheetId;
      Swal(this.response.message);
      this.updateDayHours(row);
    }
  );
}

  duplicateRow(i, rowData) {
    const dData = {
      hours: rowData.hours,
      id: '',
      notes: rowData.notes,
      project_id: rowData.project_id,
      project_name: rowData.project_name,
      task_id: rowData.task_id,
      task_name: rowData.task_name,
      timesheets_date:  rowData.timesheets_date,
      timesheets_id: '',
      timesheetsdates_id: ''
    };

    this.data.splice( i + 1, 0, dData );
    // console.log(rowData, this.data, i, dData);
  }

  deleteRow(i, data) {
    const params = new HttpParams()
    .set('timesheetDateId', data.id);
    this.getRestItems('admin/deleteDraftTimesheetOnServer', params) // deleteDraftTimesheet
    .subscribe(
      result => {
        this.response = result;
        Swal(this.response.message);
      }
    );
  }

  copyLastWeekTimesheet() {
    const params = new HttpParams()
    .set('startdate', this.startDate)
    .set('enddate', this.endDate);
    this.getRestItems('admin/timesheetcopylastweek', params) // timesheetcopylastweek
    .subscribe(
      result => {
        this.response = result;
        Swal(this.response.message);
      }
    );
  }

  submitTimesheetForApproval() {
    console.log('Submit for Approval:::::::::::::::::::::::::::', this.data, ':::::::::::::::::::::::::::')
    this.validationOnSubmitforApproval();
  const params = new HttpParams()
    .set('timesheet_id', this.timesheetId);
    this.postRestItems('admin/submitTimesheetForApproval', params) // submitTimesheetForApproval
    .subscribe(
      result => {
        this.response = result;
        Swal(this.response.message);
      }
    );
  }
}
