import { Component, OnInit,  enableProdMode, Input, ViewContainerRef, ViewChild, ViewChildren, ViewEncapsulation  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.service';
import * as projectInfo from '../globals';
import { ManpowerReportComponent } from './manpower-report/manpower-report.component';
import Swal from 'sweetalert2';

enableProdMode();

@Component({
  selector: 'app-manpower',
  templateUrl: './manpower.component.html',
  styleUrls: ['./manpower.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManpowerComponent implements OnInit {
  hasPlotter: boolean;
  imageSrc: "";
  refreshPlotting: boolean;
  plotterDetailList: Array<any> = [];
  @ViewChild(ManpowerReportComponent) private manpowerReport: ManpowerReportComponent;

  constructor(
    private appService: AppService
  ) {
    this.hasPlotter = false;
    this.refreshPlotting = false;
  }

  ngOnInit() {
    // this.appService.getAll('userInfo', '')
    // .subscribe(
    //   restItems => {
    //     // ToDo: Need to add User info in globals
    //     console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', restItems);
    //   }
    // );
  }

  getRestItems(url, params) {
    return this.appService.getAll(url, params);
  }

  refreshManpower() {
    this.manpowerReport.doRefresh();
  }

  initRefreshPlotting() {
    this.refreshPlotting = !this.refreshPlotting;
  }


  /************** By Animation move to plotting **************/
  reportToPlotting(data) {
    if(projectInfo.status == true){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. Either project is completed or budget is not approved or you do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }
    if(projectInfo.can_update_task == false){
      Swal({
        position: 'top-end',
        type: 'success',
        title: 'Action not allowed. You do not have enough rights to perform the action',
        showConfirmButton: false,
        timer: 5000
      })
      return;
    }
    Swal({
      position: 'top-end',
      type: 'success',
      title:  `${data.employee.name} is ready to plot`,
      showConfirmButton: false,
      timer: 1500
    });
    console.log(data, '@@@@@@@@@@#@@@@@@@@@@@@@@@#@@@@@@@@@@@@@@@###########')
    this.hasPlotter = true;
    this.plotterDetailList = data.employee;
    const IE = document.all;
    let tempX = 0;
    let tempY = 0;
    const element = document.getElementById('x');
    this.imageSrc = data.employee.image;
    element.style.display = 'none';
    element.classList.remove('rotate');
    element.classList.remove('placed');
    document.onmousemove = function getMouseXY(abc) {
      if (element.classList.contains('placed')) {
        element.style.display = 'none';
        return;
      }
      if (IE) { // grab the x-y pos.s if browser is IE
        tempX = data.event.clientX;
        tempY = data.event.clientY;
      } else { // grab the x-y pos.s if browser is NS
        tempX = abc.pageX;
        tempY = abc.pageY;
      }
      if (!element.classList.contains('placed')) {
      element.style.position = 'absolute';
      element.style.display = 'block';
      element.style.left = tempX + 10 + 'px';
      element.style.top = tempY + 10 + 'px';
      }
    }
  }
}
